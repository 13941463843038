import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CreateQuizView from '../views/CreateQuizView.vue'
import LobbyCreateView from '../views/LobbyCreateView.vue'
import LobbyJoinView from '../views/LobbyJoinView.vue'
import LobbyView from '../views/LobbyView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/create-quiz',
    name: 'create-quiz',
    component: CreateQuizView
  },
  {
    path: '/create-lobby',
    name: 'create-lobby',
    component: LobbyCreateView
  },
  {
    path: '/join-lobby',
    name: 'join-lobby',
    component: LobbyJoinView
  },
  {
    path: '/lobby',
    name: 'lobby',
    component: LobbyView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
