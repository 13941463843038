<template>
    <div @click="clickedBackground" class="create-quiz-table">
        <div class="edit_quiz_info_container edit_quizname_container">
            <button v-show="!edit_quizname" @click="editQuizname" class="switch_to_edit_btn edit_quizname">
                {{ quizname }}
            </button>
            <div v-show="edit_quizname" class="edit_row">
                <input maxlength="25" type="text" ref="quizname_edit_input" v-model="quizname" />
                <!--<Button @click="setQuizname">
                    <font-awesome-icon :icon="['fas', 'check']" />
                </Button>-->
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <div class="edit_quiz_info_container">
                            <button v-show="!edit_category_1" class="switch_to_edit_btn category_1_edit">
                                {{ categories[0] }}
                            </button>
                            <div v-show="edit_category_1" class="edit_row">
                                <input maxlength="15" type="text" ref="category_1_edit_input" v-model="categories[0]" />
                                <!--<Button @click="setCategory1">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </Button>-->
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="edit_quiz_info_container">
                            <button v-show="!edit_category_2" class="switch_to_edit_btn category_2_edit">
                                {{ categories[1] }}
                            </button>
                            <div v-show="edit_category_2" class="edit_row">
                                <input maxlength="15" type="text" ref="category_2_edit_input" v-model="categories[1]" />
                                <!--<Button @click="setCategory2">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </Button>-->
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="edit_quiz_info_container">
                            <button v-show="!edit_category_3" class="switch_to_edit_btn category_3_edit">
                                {{ categories[2] }}
                            </button>
                            <div v-show="edit_category_3" class="edit_row">
                                <input maxlength="15" type="text" ref="category_3_edit_input" v-model="categories[2]" />
                                <!--<Button @click="setCategory3">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </Button>-->
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="edit_quiz_info_container">
                            <button v-show="!edit_category_4" class="switch_to_edit_btn category_4_edit">
                                {{ categories[3] }}
                            </button>
                            <div v-show="edit_category_4" class="edit_row">
                                <input maxlength="15" type="text" ref="category_4_edit_input" v-model="categories[3]" />
                                <!--<Button @click="setCategory4">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </Button>-->
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="edit_quiz_info_container">
                            <button v-show="!edit_category_5" class="switch_to_edit_btn category_5_edit">
                                {{ categories[4] }}
                            </button>
                            <div v-show="edit_category_5" class="edit_row">
                                <input maxlength="15" type="text" ref="category_5_edit_input" v-model="categories[4]" />
                                <!--<Button @click="setCategory5">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </Button>-->
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>100</td>
                    <td>
                        <Button v-if="!questions_done.includes(1)" id="btn_1" outlined @click="openDialog(1, 100, 1)">
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_1" @click="openDialog(1, 100, 1)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(2)" id="btn_2" @click="openDialog(2, 100, 2)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_2" @click="openDialog(2, 100, 2)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(3)" id="btn_3" @click="openDialog(3, 100, 3)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(3, 100, 3)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(4)" id="btn_4" @click="openDialog(4, 100, 4)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(4, 100, 4)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(5)" id="btn_5" @click="openDialog(5, 100, 5)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(5, 100, 5)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td>200</td>
                    <td>
                        <Button v-if="!questions_done.includes(6)" id="btn_6" @click="openDialog(6, 200, 1)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(6, 200, 1)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(7)" id="btn_7" @click="openDialog(7, 200, 2)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(7, 200, 2)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(8)" id="btn_8" @click="openDialog(8, 200, 3)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(8, 200, 3)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(9)" id="btn_9" @click="openDialog(9, 200, 4)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(9, 200, 4)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(10)" id="btn_10" @click="openDialog(10, 200, 5)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(10, 200, 5)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td>300</td>
                    <td>
                        <Button v-if="!questions_done.includes(11)" id="btn_11" @click="openDialog(11, 300, 1)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(11, 300, 1)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(12)" id="btn_12" @click="openDialog(12, 300, 2)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(12, 300, 2)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(13)" id="btn_13" @click="openDialog(13, 300, 3)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(13, 300, 3)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(14)" id="btn_14" @click="openDialog(14, 300, 4)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(14, 300, 4)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(15)" id="btn_15" @click="openDialog(15, 300, 5)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(15, 300, 5)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td>400</td>
                    <td>
                        <Button v-if="!questions_done.includes(16)" id="btn_16" @click="openDialog(16, 400, 1)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(16, 400, 1)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(17)" id="btn_17" @click="openDialog(17, 400, 2)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(17, 400, 2)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(18)" id="btn_18" @click="openDialog(18, 400, 3)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(18, 400, 3)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(19)" id="btn_19" @click="openDialog(19, 400, 4)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(19, 400, 4)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                    <td>
                        <Button v-if="!questions_done.includes(20)" id="btn_20" @click="openDialog(20, 400, 5)" outlined>
                            <i class="fa-solid fa-plus"></i>
                        </Button>
                        <Button v-else id="btn_3" @click="openDialog(20, 400, 5)">
                            <i class="fa-solid fa-check"></i>
                        </Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Button @click="createQuiz" class="continue_btn">
            Save Quiz
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </Button>

        <Dialog :dismissableMask="true" :draggable="false" v-model:visible="show_dialog" modal header="Add question" :style="{ width: '50rem' }">
            <form class="add_question_form">
                <div class="form_row">
                    <div class=form_col>
                        <div class="form_row">
                            <div class="form_col">
                                <!-- todo: Add question type descriptions -->
                                <label><i class="fa-solid fa-layer-group fa-sm"></i> Question Type</label>
                                <Dropdown @change="resetValidation" optionValue="value" v-model="question_type_value" :options="question_type_options" optionLabel="name" >
                                    <template #option="slotProps">
                                        <div class="flex align-items-center">
                                            <div>{{ slotProps.option.name }}</div>
                                            <small class="dropdwn_description">{{ slotProps.option.description }}</small>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>
                        <div class="form_row" v-show="question_type_value !== 'order'">
                            <div class="form_col">
                                <!-- todo: Add question type descriptions -->
                                <label><i class="fa-solid fa-keyboard fa-sm"></i> Answer Type</label>
                                <Dropdown @change="resetValidation" optionValue="value" v-model="answer_type_value" :options="answer_type_options" optionLabel="name" >
                                    <template #option="slotProps">
                                        <div class="flex align-items-center">
                                            <div>{{ slotProps.option.name }}</div>
                                            <small class="dropdwn_description">{{ slotProps.option.description }}</small>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>
                        <div class="form_row">
                            <div class="form_col">
                                <label><i class="fa-solid fa-question fa-sm"></i> Question</label>
                                <InputText :invalid="question_value__invalid" type="text" v-model="question_value" />
                            </div>
                        </div>
                        <div class="form_row" v-if="question_type_value !== 'order' && answer_type_value !== 'choose'">
                            <div class="form_col">
                                <label><i class="fa-solid fa-exclamation fa-sm"></i> Answer</label>
                                <InputText :invalid="answer_value__invalid" type="text" v-model="answer_value" />
                            </div>
                        </div>
                        <div class="form_row" v-if="answer_type_value === 'choose'">
                            <div class="form_col">
                                <label><i class="fa-solid fa-list fa-sm"></i> Answer options</label>
                                <div class="form_col">
                                    <div class="form_row answer_option">
                                        <InputText v-model="answer_option_1" placeholder="Option 1"/>
                                        <div class="form_col">
                                            <Checkbox v-model="checked" />
                                            <label>Correct?</label>
                                        </div>
                                    </div>
                                    <div class="form_row answer_option">
                                        <InputText v-model="answer_option_2" placeholder="Option 2"/>
                                        <div class="form_col">
                                            <Checkbox v-model="checked" />
                                            <label>Correct?</label>
                                        </div>
                                    </div>
                                    <div class="form_row answer_option">
                                        <InputText v-model="answer_option_3" placeholder="Option 3"/>
                                        <div class="form_col">
                                            <Checkbox v-model="checked" />
                                            <label>Correct?</label>
                                        </div>
                                    </div>
                                    <div class="form_row answer_option">
                                        <InputText v-model="answer_option_4" placeholder="Option 4"/>
                                        <div class="form_col">
                                            <Checkbox v-model="checked" />
                                            <label>Correct?</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form_row" v-if="question_type_value === 'estimate'">
                            <div class="form_col">
                                <label>Allowed deviation from answer (in %)</label>
                                <InputText :invalid="allowed_deviation_value__invalid" type="text" v-model="allowed_deviation_value" />
                            </div>
                        </div>
                    </div>
                    <div class="form_col">
                        <div class="form_row" v-if="question_type_value === 'audio'">
                            <div class="form_col">
                                <label><i class="fa-solid fa-music fa-xs"></i> Audio Question<br><small>Audio that should be played as part of the question</small></label>
                                <FileUpload :invalid="audio_value__invalid" v-model="audio_upload" ref="audio_upload" id="audio_upload" mode="basic" accept=".mp3" :maxFileSize="10000000" />
                            </div>
                        </div>
                        <div class="form_row" v-if="question_type_value === 'audio'">
                            <div class="form_col">
                                <label><i class="fa-solid fa-music fa-xs"></i> Audio Answer (optional)<br><small>Audio that should be played as part of the answer</small></label>
                                <FileUpload :invalid="audio_value__invalid" v-model="audio_answer_upload" ref="audio_answer_upload" id="audio_answer_upload" mode="basic" accept=".mp3" :maxFileSize="10000000" />
                            </div>
                        </div>
                        <div class="form_row" v-if="question_type_value !== 'order'">
                            <div class="form_col">
                                <label><i class="fa-solid fa-image fa-xs"></i> Image Question <span v-if="question_type_value !== 'blurred_image'">(optional)</span><br><small>Shows an image together with the question text</small></label>
                                <FileUpload :invalid="image_value__invalid" v-model="image_upload" ref="image_upload" id="image_upload" mode="basic" customUpload accept=".jpg,.png,.webp,.jpeg,.gif" :maxFileSize="10000000" />
                            </div>
                        </div>
                        <div class="form_row" v-if="question_type_value !== 'order'">
                            <div class="form_col">
                                <label><i class="fa-solid fa-image fa-xs"></i> Image Answer (optional)<br><small>Shows an image together with answer text</small></label>
                                <FileUpload :invalid="image_answer_value__invalid" v-model="image_answer_upload" ref="image_answer_upload" id="image_answer_upload" mode="basic" customUpload accept=".jpg,.png,.webp,.jpeg,.gif" :maxFileSize="10000000" />
                            </div>
                        </div>
                        <div class="form_row" v-if="question_type_value === 'order'">
                            <div class="form_col">
                                <label><i class="fa-solid fa-arrow-down-big-small"></i> Items to sort</label>
                                <div class="order_item_inputs">
                                    <InputText v-model="order_item_name_value" placeholder="Name"/>
                                    <InputText v-model="order_item_value_value" placeholder="Value" />
                                    <InputNumber v-model="order_item_order_value" placeholder="Ordernumber (1, 2, 3, ...)" />
                                </div>
                                <Button class="add_order_item_btn" @click="addOrderItem">
                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                    Add
                                </Button>
                                <ol class="order_items_added_list">
                                    <li v-for="item in current_order_items">{{ item.order }}. {{ item.name }}</li>
                                </ol>
                                <small v-show="question_order_items__invalid" class="error">You need to add at least 3 items.</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_row button_row">
                    <!-- <ProgressSpinner v-show="loading" style="width: 50px; height: 38px" strokeWidth="5" fill="var(--surface-ground)" animationDuration="1s" aria-label="Custom ProgressSpinner" /> -->
                    <ButtonGroup>
                        <Button type="button" label="Add" @click="addQUestion" ></Button>
                        <Button type="button" label="Cancel" severity="secondary" @click="show_dialog = false" ></Button>
                    </ButtonGroup>
                </div>
            </form>
        </Dialog>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'CreateQuizTable',
    data() {
        return {
            edit_category_1: false,
            edit_category_2: false,
            edit_category_3: false,
            edit_category_4: false,
            edit_category_5: false,
            edit_quizname: false,
            quizname: 'Quizname',
            categories: [
                "Category 1",
                "Category 2",
                "Category 3",
                "Category 4",
                "Category 5"
            ],
            current_cell: {},
            show_dialog: false,
            question_value: '',
            question_value__invalid: false,
            answer_value: '',
            answer_value__invalid: false,
            allowed_deviation_value: '',
            allowed_deviation_value__invalid: false,
            image_value__invalid: false,
            audio_value__invalid: false,
            order_item_name_value: '',
            order_item_value_value: '',
            order_item_order_value: undefined,
            question_order_items__invalid: false,
            question_type_value: 'simple',
            question_type_options: [
                { name: 'Simple', description: 'Simple text question / answer', value: 'simple' },
                { name: 'Blurred Image', description: 'Guess the contents of a blurred out image', value: 'blurred_image' },
                { name: 'Audio', description: 'Guess something based on an audio (i.e. song title, artist, movie soundtrack)', value: 'audio' },
                { name: 'Order', description: 'Order items by some property (height, weight, etc.)', value: 'order' },
                //{ name: 'Estimate', description: 'Ask for an estimation of something (i.e. product costs, distance)', value: 'estimate' }
            ],
            answer_type_value: 'textinput',
            answer_type_options: [
                { name: 'Textinput', description: 'Every player can enter a single text answer', value: 'textinput' },
                { name: 'Buzzer', description: 'Players get a button to buzzer', value: 'buzzer' },
                { name: 'Choose', description: 'Players get 4 answers to choose from', value: 'choose' },
            ],
            questions_done:[],
            questions: [],
            current_order_items: []
        }
    },
    methods: {
        clickedBackground() {
            const active_element = document.activeElement;
            if (active_element.tagName !== 'INPUT') {
                this.edit_category_1 = false;
                this.edit_category_2 = false;
                this.edit_category_3 = false;
                this.edit_category_4 = false;
                this.edit_category_5 = false;
                this.edit_quizname = false;

                if (active_element.classList.contains('switch_to_edit_btn')) {
                    if (active_element.classList.contains('category_1_edit')) {
                        this.edit_category_1 = true;
                        this.$nextTick(() => {
                            this.$refs.category_1_edit_input.focus();
                        })
                    } else if (active_element.classList.contains('category_2_edit')) {
                        this.edit_category_2 = true;
                        this.$nextTick(() => {
                            this.$refs.category_2_edit_input.focus();
                        })
                    } else if (active_element.classList.contains('category_3_edit')) {
                        this.edit_category_3 = true;
                        this.$nextTick(() => {
                            this.$refs.category_3_edit_input.focus();
                        })
                    } else if (active_element.classList.contains('category_4_edit')) {
                        this.edit_category_4 = true;
                        this.$nextTick(() => {
                            this.$refs.category_4_edit_input.focus();
                        })
                    } else if (active_element.classList.contains('category_5_edit')) {
                        this.edit_category_5 = true;
                        this.$nextTick(() => {
                            this.$refs.category_5_edit_input.focus();
                        })
                    } else if (active_element.classList.contains('edit_quizname')) {
                        this.edit_quizname = true;
                        this.$nextTick(() => {
                            this.$refs.quizname_edit_input.focus();
                        })
                    }
                }
            }
        },
        editCategory1() {
            this.edit_category_1 = true;
        },
        setCategory1() {
            this.edit_category_1 = false;
        },
        editCategory2() {
            this.edit_category_2 = true;
        },
        setCategory2() {
            this.edit_category_2 = false;
        },
        editCategory3() {
            this.edit_category_3 = true;
        },
        setCategory3() {
            this.edit_category_3 = false;
        },
        editCategory4() {
            this.edit_category_4 = true;
        },
        setCategory4() {
            this.edit_category_4 = false;
        },
        editCategory5() {
            this.edit_category_5 = true;
        },
        setCategory5() {
            this.edit_category_5 = false;
        },
        editQuizname() {
            this.edit_quizname = true;
        },
        setQuizname() {
            this.edit_quizname = false;
        },
        addOrderItem() {
            if (this.order_item_name_value.length > 0 && this.order_item_value_value.length > 0 && typeof this.order_item_order_value.length !== undefined) {
                this.current_order_items.push({ name: this.order_item_name_value, value: this.order_item_value_value, order: this.order_item_order_value });
                this.current_order_items = this.current_order_items.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
                this.order_item_name_value = '';
                this.order_item_value_value = '';
                this.order_item_order_value = undefined;
            }
        },
        resetValidation() {
            this.question_value__invalid = false;
            this.answer_value__invalid = false;
            this.allowed_deviation_value__invalid = false;
            this.image_value__invalid = false;
            this.audio_value__invalid = false;
        },
        createQuiz() {
            this.$emit('createQuiz', this.questions, this.quizname, this.categories);
        },
        openDialog(btn_num, points, col) {
            this.clearForm();
            this.current_cell = { btn_num: btn_num, points: points, col: col };

            // Check if question was already created, if so get data
            let get_question;
            get_question = this.questions.filter(question => 
                question.points === points && 
                question.category === this.categories[this.current_cell.col-1]
            )[0];

            if (get_question) {
                this.edit = true;

                if (get_question.question) {
                    this.question_value = get_question.question;
                }

                if (get_question.answer) {
                    this.answer_value = get_question.answer;
                }

                if (get_question.deviation) {
                    this.allowed_deviation_value = get_question.deviation;
                }

                /*if (get_question.image) {
                    this.$refs.image_upload.files.push(get_question.image)
                }*/

                switch (get_question.type) {
                    case 1:
                        this.type_value = 'simple';
                        break;
                    case 2:
                        this.type_value = 'blurred_image';
                        break;
                    case 3:
                        this.type_value = 'audio';
                        break;
                    case 4:
                        this.type_value = 'estimate';
                        break;
                    case 5:
                        this.type_value = 'order';
                        break;
                }
            }

            this.show_dialog = true;
        },
        createQuestionObject() {

            let question_obj = {
                points: this.current_cell.points,
                category: this.categories[this.current_cell.col-1],
                question: this.question_value,
                answer: this.answer_value,
                input_type: this.answer_type_value,
                image: this.$refs.image_upload.files.length > 0 ? this.$refs.image_upload.files[0] : '',
                image_answer: this.$refs.image_answer_upload.files.length > 0 ? this.$refs.image_upload.files[0] : '',
            }

            switch (this.question_type_value) {
                case 'simple':
                    question_obj['type'] = 1;
                    break;
                
                case 'blurred_image':
            
                    question_obj['type'] = 2;
                    break;
                
                case 'audio':
                    const audio = this.$refs.audio_upload.files[0];
                    const audio_answer = this.$refs.audio_answer_upload.files.length > 0 ? this.$refs.audio_answer_upload.files[0] : '';

                    question_obj['type'] = 3;
                    question_obj['audio'] = audio;
                    question_obj['audio_answer'] = audio_answer;
                    break;
                
                case 'order':
                    const order_items = this.current_order_items;
                    this.current_order_items = [];

                    question_obj['type'] = 5;
                    question_obj['order_items'] = JSON.stringify(order_items);
                    break;
            }

            return question_obj;
        },
        addQUestion() {
            const valid = this.validateForm();

            if (valid) {

                const question_obj = this.createQuestionObject();

                // Check if question already exists, if so replace with new object
                const index = this.questions.findIndex(question => 
                    question.points === this.current_cell.points && 
                    question.category === this.categories[this.current_cell.col-1]
                )

                if (index > -1) {
                    this.questions.splice(index, 1, question_obj)
                } else {
                    this.questions.push(question_obj);
                    this.questions_done.push(this.current_cell.btn_num);
                }

                this.clearForm();
                this.show_dialog = false;
            }
        },
        validateForm() {
            let valid = true;

            switch (this.question_type_value) {
                case 'simple':
                    if (!this.question_value) {
                        valid = false;
                        this.question_value__invalid = true;
                    } else {
                        this.question_value__invalid = false;
                    }

                    if (!this.answer_value) {
                        valid = false;
                        this.answer_value__invalid = true;
                    } else {
                        this.answer_value__invalid = false;
                    }
                    
                    break;
                
                case 'blurred_image':
                    if (!this.question_value) {
                        valid = false;
                        this.question_value__invalid = true;
                    } else {
                        this.question_value__invalid = false;
                    }

                    if (!this.$refs.image_upload.files.length > 0) {
                        valid = false;
                        this.image_value__invalid = true;
                    } else {
                        this.image_value__invalid = false;
                    }

                    if (!this.answer_value) {
                        valid = false;
                        this.answer_value__invalid = true;
                    } else {
                        this.answer_value__invalid = false;
                    }

                    break;
                
                case 'audio':
                    if (!this.$refs.audio_upload.files.length > 0) {
                        valid = false;
                        this.audio_value__invalid = true;
                    } else {
                        this.audio_value__invalid = false;
                    }

                    if (!this.question_value) {
                        valid = false;
                        this.question_value__invalid = true;
                    } else {
                        this.question_value__invalid = false;
                    }

                    if (!this.answer_value.length > 0) {
                        valid = false;
                        this.answer_value__invalid = true;
                    } else {
                        this.answer_value__invalid = false;
                    }

                    break;
                
                case 'estimate':
                    if (!this.question_value.length > 0) {
                        valid = false;
                        this.question_value__invalid = true;
                    } else {
                        this.question_value__invalid = false;
                    }

                    if (!this.answer_value.length > 0) {
                        valid = false;
                        this.answer_value__invalid = true;
                    } else {
                        this.answer_value__invalid = false;
                    }

                    if (!this.allowed_deviation_value.length > 0) {
                        valid = false;
                        this.allowed_deviation_value__invalid = true;
                    } else {
                        this.allowed_deviation_value__invalid = false;
                    }

                    break;

                case 'order':
                    if (!this.question_value.length > 0) {
                        valid = false;
                        this.question_value__invalid = true;
                    } else {
                        this.question_value__invalid = false;
                    }

                    if (this.current_order_items.length < 3) {
                        valid = false;
                        this.question_order_items__invalid = true;
                    } else {
                        this.question_order_items__invalid = false;
                    }

                    break;
            }
            return valid;
        },
        clearForm() {
            this.answer_value = '';
            this.question_value = '';
            this.type_value = "simple";
            this.allowed_deviation_value = '';
        }
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  :deep(.p-button-group) {
    width: 100%;
  }

  :deep(.p-button-group > .p-button) {
    width: 50%;
  }

  :deep(.p-button) {
    max-width: 100%;
  }

  .answer_option > input {
    width: 100%;
  }

  .answer_option > div {
    width: 100px;
    gap: 0px;
    align-items: center;
  }

  .answer_option > div > label {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  .add_question_form i {
    padding-right: 5px;
  }

  .add_question_form > .form_row {
    gap: 40px;
  }

  .add_question_form > .form_row > .form_col {
    width: 50%;
    max-width: 50%;
  }

  .add_question_form label {
    margin-top: 15px;
  }

    .error {
        color: red;
    }

    .order_items_added_list {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        padding: 10px;
    }

    .order_item_inputs {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }

    :deep(.p-dropdown-panel) {
        max-width: 100px !important;
    }

    :deep(.add_order_item_btn) {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    table {
        width: 100%;
    }

    th, td, tr {
        padding: 10px;
    }

    th:not(:nth-of-type(1)), td:not(:nth-of-type(1)) {
        width: 200px;
        max-width: 200px;
    }

    th:nth-of-type(1), td:nth-of-type(1) {
        width: 80px;
    }

    th > div {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    td button {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 80px;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .form_row {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .form_col {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }

    .create-quiz-table {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: 1200px;
        margin-top: 100px;
    }

    .continue_btn {
        width: 200px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        margin-top: 30px;
    }

    .button_row {
        margin-top: 20px;
    }

    .button_row button {
        width: 175px;
    }

    .switch_to_edit_btn {
        background-color: transparent;
        border: none;
        font-size: 16px;
        color: hsl(210, 29%, 80%);
        padding: 10px 20px;
        border-radius: 5px;
        font-family: Avenir, Helvetica, Arial, sans-serif
    }

    .switch_to_edit_btn:hover {
        background-color: rgba(255, 255, 255, 0.102);
    }

    .edit_quizname_container > button {
        font-size: 23px;
        width: 350px;
    }

    .edit_quizname_container > div {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .edit_quiz_info_container {
        height: 42px;
        width: 100%;
    }

    .edit_row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 100%;
    }

    .edit_row button {
        border-top-left-radius: 0;
       border-bottom-left-radius: 0;
       min-width: 40px;
       width: 40px;
       padding: 0;
       display: flex;
       justify-content: center;
       align-items: center;
    }

    .edit_row input {
        border-radius: 5px;
        width: 100%;
        height: 42px;
        background-color: hsl(210, 15%, 24%);
        color: hsl(210, 29%, 80%);
        border: 1px solid hsl(210, 29%, 80%);
        text-align: center;
        font-weight: normal;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-size: 16px;
    }

    .edit_quizname_container input {
        height: 51.5px;
        border-radius: 5px;
        max-width: 350px;
        font-size: 23px;
    }
    
  </style>
  