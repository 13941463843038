<template>
    <Card v-show="timerCount > 0" class="timer">
        <template #title>
            <p class="timer_title">Timer</p>
        </template>
        <template #content>
            <p class="timer_time">{{ timerCount }}</p>
        </template>
    </Card>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Timer',
        data() {
            return {
                timerCount: 0
            }
        },
        watch: {

            timerCount: {
                handler(value) {

                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }

                },
                immediate: true
            }

        },
        sockets: {
            startTimer: function (seconds) {
                this.timerCount = seconds;
            }
        }
    })
</script>

<style scoped>

.hide {
    display: none !important;
}

.timer {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
}

.timer p {
    margin: 0;
}

.timer_time {
    font-size: 30px;
    font-weight: bold;
}

.timer_title {
    font-size: 20px;
}

</style>