<template>
    <div class="create-quiz-name">
        <div class="flex_col">
            <h1>Quizname</h1>
            <div class="input_container">
                <InputText :invalid="invalid" v-model="quiz_name" type="text" />
                
            </div>
            <small v-show="invalid">Quizname already exists.</small>
            <Button :disabled="quiz_name.length === 0" @click="setQuizName">
                <ProgressSpinner style="width: 20px; height: 20px; stroke: white;" v-show="loading" strokeWidth="5" class="loading_spinner" />
                <div v-show="!loading">
                    Continue
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </div>
            </Button>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CreateQuizName',
    data() {
        return {
            quiz_name: '',
            invalid: false,
            loading: false
        }
    },
    methods: {
        async setQuizName() {
            // Check if name already exists in db
            this.loading = true;
            let res = await fetch(this.$store.state.API_URL + "/check-quiz-name", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ quiz_name: this.quiz_name })
            });

            if (res.status === 200) {
                this.invalid = false;
                this.$emit('setQuizName', this.quiz_name);
            } else {
                this.invalid = true;
            }

            this.loading = false;
        }
    }
})

</script>

<style scoped>

small {
    color: red;
}

.create-quiz-name {
    display: flex;
    justify-content: center;
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 500px;
}

button {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

input {
    width: 100%;
}

:deep(.p-progress-spinner-circle) {
    stroke: white !important;
}

</style>