<template>
    <Button @click="buzzerPressed()" :class="{ buzzed: pressed }" class="buzzer">
        <p v-if="!pressed">Click to buzz</p>
        <p v-else>You buzzed! Wait for the lobby leader to call you up.</p>
    </Button>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Buzzer',
        data() {
            return {
                pressed: false
            }
        },
        methods: {
            buzzerPressed() {
                if (!this.pressed) {
                    this.pressed = true;
                    this.$socket.emit("buzzer-pressed", this.$store.state.lobbyId, this.$store.state.username)
                }
            }
        },
        sockets: {
            resetBuzzers: function () {
                this.pressed = false;
            }
        }
    })

</script>

<style scoped>

.buzzer {
    width: 600px;
    height: 100px;
    background-color: #DF3636;
    border-radius: 10px;
    border: none;
    box-shadow: 0 -20px 0px #841515 inset;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
}

.buzzer > p {
    padding-bottom: 10px;
}

.buzzed {
    background-color: #E6A360;
    box-shadow: 0 -10px 0px #9C6630 inset;
}

.buzzed > p {
    padding-bottom: 3px;
}

</style>