<template>
    <div class="lobby-wait">
        <ProgressSpinner strokeWidth="5" class="loading_spinner" />
        <p>Waiting for quiz to start</p>
        <div>
            <iframe src="https://giphy.com/embed/Dg4TxjYikCpiGd7tYs" width="380" height="380" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/raccoon-pedro-Dg4TxjYikCpiGd7tYs"></a></p>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import LobbyUser from '../class/LobbyUser.js';
    import LobbyPlayerList from '../components/Lobby/LobbyPlayerList.vue';

    export default defineComponent({
        name: 'LobbyWait',
        components: {
            LobbyPlayerList
        },
        sockets: {
            setPlayerList: function (player_list) {
                this.$store.state.playerList = player_list;
            },
            setLobbyId: function (lobby_id) {
                this.$store.state.lobbyId = lobby_id;
            },
            newPlayer: function (player) {
                this.$store.state.playerList.push(player);
            },
            removePlayer: function (username) {
                const index = this.$store.state.playerList.map(function(e) { return e.username; }).indexOf(username);

                if (index >= 0) {
                    this.$store.state.playerList.splice(index, 1);
                }
            },
            startLobby: function (data) {
                console.log(data)
                this.$store.state.quizId = data.quiz_id;
                this.$store.state.irl_mode = data.irl_mode;
                this.$router.push({ path: '/lobby' });
            },
        }
    })
</script>

<style scoped>

    .lobby-wait {
        margin-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lobby-wait > div {
        margin-top: 40px;
    }

    .lobby-wait iframe {
        border-radius: 10px;
    }

    @media (max-width: 600px) {
        .lobby-wait {
            margin-top: 50px;
        }
    }
</style>