<template>
    <div>
        <Card class="answer_card">
            <template #content>
                <p><b>Answer:</b></p>
                <p class="answer_text">{{ question_answer }}</p>
            </template>
        </Card>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'QuestionAnswer',
        props: {
            question_answer: String
        },
    })

</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


</style>