<template>
    <div class="order-question">
        <div class="tags_row">
            <Tag :value="selected_question.category" severity="danger" />
            <Tag :value="selected_question.question_points + ' Points'" severity="warning" />
        </div>
        <p class="question_text">{{ selected_question.question_text }}</p>
        <ul class="order_items_list">
            <li>Biggest</li>
            <li class="order_item" v-for="item in selected_question.question_order_items">
                {{ item.name }}
                <ButtonGroup v-if="!$store.state.isAdmin">
                    <Button :disabled="answer_locked" @click="up(item.name)">
                        <font-awesome-icon :icon="['fas', 'chevron-up']" />
                    </Button>
                    <Button :disabled="answer_locked" @click="down(item.name)">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                    </Button>
                </ButtonGroup>
            </li>
            <li>Smallest</li>
        </ul>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Order',
        props: {
            selected_question: Object,
            answer_locked: Boolean
        },
        methods: {
            swap(array, index_1, index_2) {
                array[index_1] = array.splice(index_2, 1, array[index_1])[0];
                return array;
            },
            up(name) {
                const index_1 = this.selected_question.question_order_items.map(e => e.name).indexOf(name);
                if (index_1 > 0) {
                    this.swap(this.selected_question.question_order_items, index_1, index_1-1);
                }
            },
            down(name) {
                const index_1 = this.selected_question.question_order_items.map(e => e.name).indexOf(name);
                if (index_1 < this.selected_question.question_order_items.length - 1) {
                    this.swap(this.selected_question.question_order_items, index_1, index_1+1);
                }
            }
        },
        data() {
            return {

            }
        },
    })

</script>

<style scoped>
.order-question {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

p {
    text-align: left;
}

.question_text {
    font-weight: 500;
    font-size: 18px;
    margin: 30px 0;
}

.order_items_list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order_items_list > li:last-of-type, .order_items_list > li:first-of-type {
    text-align: left;
    font-size: 12px;
} 

.order_item {
    border-radius: 5px;
    padding: 10px 20px;
    background-color: rgb(165, 205, 250);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>