<template>
    <div class="user_answer_row">
        <Button :class="{ isLocked: answer_locked }" class="lock_in_btn" :disabled="answer_locked || answer_locked_by_admin" @click="logInAnswer">
            <font-awesome-icon v-show="!answer_locked && !answer_locked_by_admin" :icon="['fas', 'lock-open']" />
            <font-awesome-icon v-show="answer_locked || answer_locked_by_admin" :icon="['fas', 'lock']" />
        </Button>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'OrderLockButton',
        props: {
            answer_locked_by_admin: Boolean
        },
        data() {
            return {
                answer_locked: false
            }
        },
        methods: {
            lockPlayerInput() {
                this.answer_locked = true;
            },
            logInAnswer() {
                this.answer_locked = true;
                this.$emit("logInAnswerOrderQuestion");
            }
        },
        sockets: {
            unlockAnswerInput: function () {
                this.answer_locked = false;
            },
            closeQuestion: function () {
                this.answer_locked = false;
                this.user_answer_value = '';
            }
        }
    })

</script>

<style scoped>

.lock_in_btn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

</style>