<template>
    <div class="lobby-select-question">
        <table v-show="!this.$store.state.irl_mode || this.$store.state.isAdmin" class="quiz_table">
            <thead>
                <tr>
                    <th></th>
                    <th>{{ quiz.categories[0] }}</th>
                    <th>{{ quiz.categories[1] }}</th>
                    <th>{{ quiz.categories[2] }}</th>
                    <th>{{ quiz.categories[3] }}</th>
                    <th>{{ quiz.categories[4] }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>100</td>
                    <td>
                        <Button id="btn_1" @click="selectQuestion($event, '1', 100, 1)"></Button>
                    </td>
                    <td>
                        <Button id="btn_2" @click="selectQuestion($event, '2', 100, 2)"></Button>
                    </td>
                    <td>
                        <Button id="btn_3" @click="selectQuestion($event, '3', 100, 3)"></Button>
                    </td>
                    <td>
                        <Button id="btn_4" @click="selectQuestion($event, '4', 100, 4)"></Button>
                    </td>
                    <td>
                        <Button id="btn_5" @click="selectQuestion($event, '5', 100, 5)"></Button>
                    </td>
                </tr>
                <tr>
                    <td>200</td>
                    <td>
                        <Button id="btn_6" @click="selectQuestion($event, '6', 200, 1)"></Button>
                    </td>
                    <td>
                        <Button id="btn_7" @click="selectQuestion($event, '7', 200, 2)"></Button>
                    </td>
                    <td>
                        <Button id="btn_8" @click="selectQuestion($event, '8', 200, 3)"></Button>
                    </td>
                    <td>
                        <Button id="btn_9" @click="selectQuestion($event, '9', 200, 4)"></Button>
                    </td>
                    <td>
                        <Button id="btn_10" @click="selectQuestion($event, '10', 200, 5)"></Button>
                    </td>
                </tr>
                <tr>
                    <td>300</td>
                    <td>
                        <Button id="btn_11" @click="selectQuestion($event, '11', 300, 1)"></Button>
                    </td>
                    <td>
                        <Button id="btn_12" @click="selectQuestion($event, '12', 300, 2)"></Button>
                    </td>
                    <td>
                        <Button id="btn_13" @click="selectQuestion($event, '13', 300, 3)"></Button>
                    </td>
                    <td>
                        <Button id="btn_14" @click="selectQuestion($event, '14', 300, 4)"></Button>
                    </td>
                    <td>
                        <Button id="btn_15" @click="selectQuestion($event, '15', 300, 5)"></Button>
                    </td>
                </tr>
                <tr>
                    <td>400</td>
                    <td>
                        <Button id="btn_16" @click="selectQuestion($event, '16', 400, 1)"></Button>
                    </td>
                    <td>
                        <Button id="btn_17" @click="selectQuestion($event, '17', 400, 2)"></Button>
                    </td>
                    <td>
                        <Button id="btn_18" @click="selectQuestion($event, '18', 400, 3)"></Button>
                    </td>
                    <td>
                        <Button id="btn_19" @click="selectQuestion($event, '19', 400, 4)"></Button>
                    </td>
                    <td>
                        <Button id="btn_20" @click="selectQuestion($event, '20', 400, 5)"></Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-show="this.$store.state.irl_mode && !this.$store.state.isAdmin">
            <ProgressSpinner style="width: 80px; height: 80px; stroke: white;" strokeWidth="5" class="loading_spinner" />
            <h1>Selecting question</h1>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'LobbySelectQuestion',
        props: {
            quiz: Object
        },
        methods: {
            selectQuestion(event, id, row, col) {
                if (this.$store.state.isAdmin) {
                    this.$socket.emit('select-question', this.$store.state.lobbyId, id, row, col)

                    document.getElementById('btn_'+id).classList.add('puff-out-center')
                    document.getElementById('btn_'+id).setAttribute("disabled", "disabled");

                    setTimeout(() => {
                        document.getElementById('btn_'+id).classList.remove('puff-out-center');
                        document.getElementById('btn_'+id).classList.add('hide');
                        this.$emit('openQuestion', row, col);
                    }, 1000);
                }
            }
        },
        sockets: {
            selectQuestion: function (data) {
                document.getElementById('btn_'+data.btn_id).classList.add('puff-out-center')
                document.getElementById('btn_'+data.btn_id).setAttribute("disabled", "disabled");

                setTimeout(() => {
                    document.getElementById('btn_'+data.btn_id).classList.remove('puff-out-center');
                    document.getElementById('btn_'+data.btn_id).classList.add('hide');
                    this.$emit('openQuestion', data.row, data.col);
                }, 1000);
            }
        }
    })
</script>

<style scoped>


    @keyframes puff-out-center {
    0% {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
    }
    15% {
        filter: blur(0px);
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        filter: blur(4px);
        opacity: 0;
    }
    }

    .hide {
        display: none;
    }

    .puff-out-center {
        animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    }

    .lobby-select-question {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        gap: 40px;
    }

    .quiz_table tr {
        padding: 10px;
        min-height: 120px;
    }

    .quiz_table th, .quiz_table td {
        padding: 10px;
    }

    .quiz_table th:not(:first-of-type), .quiz_table td:not(:first-of-type) {
        min-width: 220px;
        min-height: 120px;
    }

    .quiz_table th:first-of-type, .quiz_table td:first-of-type {
        min-width: 100px;
        min-height: 120px;
    }

    .quiz_table td button {
        width: 200px;
        height: 100px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>