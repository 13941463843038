<template>
    <div class="admin-menu-bar">
        <Button v-tooltip="{ value: 'Open Question', showDelay: 1000, hideDelay: 300 }" v-show="question_selected" @click="showQuestion" severity="success" :outlined="show_question" >
            <i class="fa-solid fa-question"></i>
        </Button>
        <!-- <Button v-tooltip="{ value: 'Play Audio', showDelay: 1000, hideDelay: 300 }" v-show="selected_question_type && selected_question_type === 'audio'" @click="playAudio" severity="warning" outlined >
            <font-awesome-icon :icon="['fas', 'play']" />
        </Button>
        <Button v-tooltip="{ value: 'Pause Audio', showDelay: 1000, hideDelay: 300 }" v-show="question_selected && selected_question_type === 'audio'" @click="pauseAudio" severity="warning" outlined >
            <font-awesome-icon :icon="['fas', 'pause']" />
        </Button> -->
        <Button v-tooltip="{ value: 'Lock Player Inputs', showDelay: 1000, hideDelay: 300 }" v-show="question_selected" @click="lockPlayerInputs" severity="warning" :outlined="player_inputs_locked" >
            <i class="fa-solid fa-lock"></i>
        </Button>
        <Button v-tooltip="{ value: 'Reset Buzzers', showDelay: 1000, hideDelay: 300 }" v-show="question_selected && selected_question_type === 'blurred_image'" @click="resetBuzzers" severity="warning" outlined >
            <i class="fa-solid fa-rotate-left"></i>
        </Button>
        <Button v-tooltip="{ value: 'Reduce Blur', showDelay: 1000, hideDelay: 300 }" v-show="question_selected && selected_question_type === 'blurred_image'" @click="reduceBlur" severity="warning" outlined >
            <i class="fa-duotone fa-solid fa-angles-down"></i>
        </Button>
        <Button v-tooltip="{ value: 'Show Answer', showDelay: 1000, hideDelay: 300 }" v-show="question_selected" @click="showAnswer" severity="warning" :outlined="show_answer" >
            <i class="fa-solid fa-exclamation"></i>
        </Button>
        <Button v-tooltip="{ value: 'Close Question & Show Summary', showDelay: 1000, hideDelay: 300 }" v-show="question_selected" @click="showSummary" severity="danger" >
            <i class="fa-solid fa-arrow-left-from-bracket"></i>
        </Button>
        <Divider v-show="question_selected" />
        <Button @click="showPlayerOverview" severity="success">
            <i class="fa-duotone fa-solid fa-users"></i>
        </Button>
        <!--<Button @click="startTimer(15)" severity="info" outlined>
            <font-awesome-icon :icon="['fas', 'stopwatch']" />
        </Button>
        <Button severity="help" outlined>
            <font-awesome-icon :icon="['fas', 'champagne-glasses']" />
        </Button>
        <Button severity="help" outlined>
            <font-awesome-icon :icon="['fas', 'person-harassing']" />
        </Button>-->
        <Button v-tooltip="{ value: 'Show Lobbycode', showDelay: 1000, hideDelay: 300 }" @click="showLobbyId" severity="secondary">
            <i class="fa-solid fa-hashtag"></i>
        </Button>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'AdminMenuBar',
        props: {
            question_selected: Boolean,
            selected_question_type: String
        },
        data() {
            return {
                show_question: false,
                show_answer: false,
                player_inputs_locked: false
            }
        },
        methods: {
            lockPlayerInputs() {
                this.player_inputs_locked = true;
                this.$socket.emit("lock-player-inputs", this.$store.state.lobbyId);
            },
            showPlayerOverview() {
                this.$emit("showPlayerOverview");
            },
            resetBuzzers() {
                this.$emit("resetBuzzers");
            },
            startTimer(seconds) {
                this.$socket.emit("start-timer", this.$store.state.lobbyId, seconds);
            },
            showQuestion() {
                this.show_question = true;
                this.$socket.emit('show-question', this.$store.state.lobbyId);
            },
            playAudio() {
                this.$emit("playAudio");
            },
            pauseAudio() {
                this.$emit("pauseAudio");
            },
            reduceBlur() {
                this.$emit("reduceBlur");
            },
            showAnswer() {
                this.show_answer = true;
                this.$socket.emit('show-answer', this.$store.state.lobbyId);
                if (this.selected_question_type === 'blurred_image') {
                    this.$emit("unblurImage");
                }
            },
            showSummary() {
                this.$emit('showSummary');
            },
            resetValues() {
                this.show_question = false;
                this.show_answer = false;
                this.player_inputs_locked = false;

                this.resetBuzzers();
            },
            showLobbyId() {
                alert(this.$store.state.lobbyId);
            }
        }
    })

</script>

<style scoped>

.admin-menu-bar {
    background-color: hsl(210, 29%, 9%);
    padding: 10px 20px;
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    left: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    /*box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18);*/
}

.admin-menu-bar button {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

</style>