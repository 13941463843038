<template>
    <div class="audio-question">
        <div class="tags_row">
            <Tag :value="selected_question.category" severity="danger" />
            <Tag :value="selected_question.question_points + ' Points'" severity="warning" />
        </div>
        <p class="question_text">{{ selected_question.question_text }}</p>
        <!-- <ToggleButton v-model="play" onLabel="Audio playing" offLabel="Audio paused"></ToggleButton> -->
        <audio v-show="!this.$store.state.irl_mode || this.$store.state.isAdmin" controls ref="audio" id="audio" :volume=".2" :src="this.$store.state.API_URL + '/uploads/' + selected_question.question_audio" ></audio>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Audio',
        props: {
            selected_question: Object,
        },
        data() {
            return {
                user_answer_value: '',
                answer_locked: false,
                play: false
            }
        },
        methods: {
            logInAnswer() {
                this.answer_locked = true;
                this.$emit("logInAnswer", this.user_answer_value);
            }
        }
    })

</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.audio-question {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

audio {
    border-radius: 5px;
}

p {
    text-align: left;
}

.user_answer_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
}

.user_answer_row input {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.user_answer_row button {
    width: 170px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.answer_card {
    background-color: transparent;
    color: white;
}


</style>