import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// PrimeVue
import PrimeVue from 'primevue/config';
//import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primevue/resources/themes/aura-light-blue/theme.css';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';
import Steps from 'primevue/steps';
import Card from 'primevue/card';
import InputGroup from 'primevue/inputgroup';
import Avatar from 'primevue/avatar';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Tag from 'primevue/tag';
import Divider from 'primevue/divider';
import Knob from 'primevue/knob';
import Checkbox from 'primevue/checkbox';
import ProgressBar from 'primevue/progressbar';
import ToggleButton from 'primevue/togglebutton';
import Badge from 'primevue/badge';
import ConfirmDialog from 'primevue/confirmdialog';
import InputNumber from 'primevue/inputnumber';

// Toast Plugin
import 'vue-toast-notification/dist/theme-bootstrap.css';

// Socket io
import ToastPlugin from 'vue-toast-notification';
import VueSocketIO from 'vue-3-socket.io';

createApp(App)
    .use(store)
    .use(router)
    .use(PrimeVue)
    .component("Dropdown", Dropdown)
    .component("Dialog", Dialog)
    .component("Button", Button)
    .component("ButtonGroup", ButtonGroup)
    .component("InputText", InputText)
    .component("InputNumber", InputNumber)
    .component("FileUpload", FileUpload)
    .component("ProgressSpinner", ProgressSpinner)
    .component("Steps", Steps)
    .component("Card", Card)
    .component("Avatar", Avatar)
    .component("Tag", Tag)
    .component("ToggleButton", ToggleButton)
    .component("ProgressBar", ProgressBar)
    .component("Checkbox", Checkbox)
    .component("Knob", Knob)
    .component("Badge", Badge)
    .component("Divider", Divider)
    .component("InputGroup", InputGroup)
    .component("InputGroupAddon", InputGroupAddon)
    .directive('tooltip', Tooltip)
    .directive('ConfirmDialog', ConfirmDialog)
    .use(ToastPlugin)
    .use(new VueSocketIO({
        debug: false,
        connection: process.env.VUE_APP_BACKEND_URL
        //connection: 'http://localhost:8000',
        //connection: 'http://felixthiele.me:8000'
    }))
    .mount('#app')
