<template>
    <div class="lobby-creation">
        <div v-show="loading">
            <ProgressSpinner strokeWidth="5" class="loading_spinner" />
            <p>Creating lobby</p>
        </div>
        <div v-show="!loading">
            <h1>Create Lobby</h1>
            <div class="flex_col">
                <Dropdown v-model="selected_quiz" filter :options="quiz_list" optionLabel="name" valueLabel="value" placeholder="Select a Quiz"  />
                <small v-show="quiz_selection_error" class="quiz_selection_error">No quiz selected</small>
                <div class="lobby_code_container">
                    <Button @click="copyLobbyId" >
                        {{ this.lobby_id }}
                        <font-awesome-icon :icon="['fas', 'copy']" />
                    </Button>
                    <p v-show="show_copy_msg">Code copied!</p>
                </div>
                <!--<div class="irl_mode_add_user_container" v-show="irl_mode">
                    <div>
                        <InputText v-model="username_value" placeholder="Player" />
                        <Button @click="addUser">
                            <font-awesome-icon icon="plus" />
                        </Button>
                    </div>
                    <small v-show="username_error" class="username_error_msg">Username already exists</small>
                </div>-->         
                <Button class="start_lobby_btn" @click="startLobby">
                    Start
                    <font-awesome-icon :icon="['fas', 'play']" />
                </Button>
                <div class="lobby_irl_mode_container">
                    <Checkbox :binary="true" v-model="irl_mode" />
                    <label>IRL Mode</label>
                </div>
            </div>
            <div class="players_container">
                <div class="player_item" v-for="player in this.$store.state.playerList">
                    <p>{{ player.username }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LobbyUser from '../class/LobbyUser.js';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'LobbyCreation',
        data() {
            return {
                username_error: false,
                irl_mode: false,
                loading: true,
                show_copy_msg: false,
                username_value: '',
                selected_quiz: '',
                quiz_list: [],
                lobby_id: "",
                quiz_selection_error: false
            }
        },
        methods: {
            startLobby() {
                this.quiz_selection_error = false;
                if (this.selected_quiz) {
                    this.$store.state.quizId = this.selected_quiz.value;
                    this.$store.state.irl_mode = this.irl_mode;
                    this.$socket.emit('start-lobby', this.lobby_id, this.selected_quiz.value, this.irl_mode);
                    this.$router.push({ path: '/lobby' });
                } else {
                    this.quiz_selection_error = true;
                }
            },
            copyLobbyId() {
                navigator.clipboard.writeText(this.lobby_id);
                this.show_copy_msg = true;
                const myTimeout = setTimeout(() => {
                    this.show_copy_msg = false;
                }, 5000);
            },
            addUser() {
                if (this.username_value.length > 0) {
                    this.username_error = false;
                    if (this.$store.state.playerList.filter(player => player.username === this.username_value).length > 0) {
                        this.username_error = true;
                    } else {
                        this.$store.state.playerList.push(new LobbyUser(this.username_value, '', 0));
                        this.username_value = '';
                    }
                }
            }
        },
        async mounted() {
            let res = await fetch(this.$store.state.API_URL + "/get-quiz-names", {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            })

            res = await res.json()
            this.quiz_list = res.quiz_names;

            this.$socket.emit("create-lobby");
        },
        sockets: {
            setLobbyId: function (lobby_id) {
                this.$store.state.lobbyId = lobby_id;
                this.lobby_id = lobby_id;
                this.loading = false;
            },
        },
    })
</script>

<style scoped>

    .quiz_selection_error {
        color: red;
    }

    .lobby_irl_mode_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
    }


    .lobby-creation {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-top: 200px;
    }

    .flex_col {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 500px;
    }

    button {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
    }

    .lobby_code_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .lobby_code_container button {
        width: 100%;
    }

    .lobby_code_container p {
        width: 100%;
        text-align: left;
        position: absolute;
        left: 530px;
        top: -7px;
    }

    .users_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .users_container ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .users_container ul li {
        padding: 10px 20px;
        background-color: white;
        border-radius: 5px;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    .users_container ul li p {
        color: black;
        margin: 0;
    }

    .irl_mode_add_user_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .irl_mode_add_user_container > div {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
    }

    .irl_mode_add_user_container input {
        width: 100%;
    }

    .username_error_msg {
        color: red;
    }

    .start_lobby_btn {
        margin-top: 20px;
    }

    .players_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
    }

    .player_item {
        border-radius: 5px;
        padding: 10px 20px;
        background-color: white;
        color: hsl(210, 29%, 9%);
    }

    .player_item > p {
        margin: 0;
    }
</style>