<template>
    <div class="lobby-player-list">
        <PlayerListItem v-for="player in playerlist" :ref="player.username" :username="player.username" :answer="player.answer" :points="player.points" :buzz_cnt="player.buzz_cnt" />
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import PlayerListItem from './PlayerListItem.vue';

export default defineComponent({
    name: 'LobbyPlayerList',
    props: {
        playerlist: Array,
        buzz_cnt: Number
    },
    components: {
        PlayerListItem
    },
    data() {
        return {

        }
    },
    sockets: {
        /*answerLocked: function (data) {
            this.playerlist.map(player => {
                if (player.username === data.username) {
                    player.answer = data.answer;
                };
            });
        },*/
        addPoints: function (username) {
            this.$store.state.playerList.map(player => {
                if (player.username === username) {
                    player.points += 50;
                }
            });
        },
        substractPoints: function (username) {
            this.$store.state.playerList.map(player => {
                if (player.username === username) {
                    player.points -= 50;
                }
            });
        },
    }
})

</script>

<style scoped>

.lobby-player-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    overflow-y: scroll;
    height: 100vh;
    padding: 40px 0 20px;
}

:deep(.p-button) {
    height: 25px;
}

</style>