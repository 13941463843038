<template>
    <div class="lobby-show-question">
        <div v-show="!$store.state.isAdmin && !showQuestion">
            <ProgressSpinner style="width: 80px; height: 80px; stroke: white;" strokeWidth="5" class="loading_spinner" />
            <p>Waiting for question</p>
        </div>

        <Transition>
            <Card class="question_card" v-show="this.$store.state.isAdmin && !this.$store.state.irl_mode || showQuestion">
                <template #content>
                    <SimpleQuestion v-if="selected_question.question_type === 'simple_question'" :selected_question="selected_question" />
                    <BlurredImageQuestion v-if="selected_question.question_type === 'blurred_image'" :blur_lvl="blur_lvl" :selected_question="selected_question" />
                    <AudioQuestion v-if="selected_question.question_type === 'audio'" :selected_question="selected_question" />
                    <OrderQuestion v-if="selected_question.question_type === 'order'" :selected_question="selected_question" :answer_locked="answer_locked" />

                    <TextAnswer v-show="!$store.state.isAdmin && (selected_question.question_type === 'simple_question' || selected_question.question_type === 'audio')" ref="textAnswer" @logInAnswer="logInAnswer" />
                    <OrderLockAnswer @logInAnswerOrderQuestion="logInAnswerOrderQuestion" :answer_locked_by_admin="answer_locked" v-show="!$store.state.isAdmin && (selected_question.question_type === 'order') && !show_answer" ref="order_lock_btn" />
                    <Transition name="answer">
                        <div v-show="show_answer || $store.state.isAdmin && !$store.state.irl_mode" v-if="selected_question.question_type !== 'order'" class="answer_container">
                            <p class="question_answer">{{ selected_question.question_answer }}</p>
                            <img v-show="!this.$store.state.irl_mode || this.$store.state.isAdmin" v-if="selected_question.question_img_answer" :src="this.$store.state.API_URL + '/uploads/' + selected_question.question_img_answer" />
                            <audio v-show="!this.$store.state.irl_mode || this.$store.state.isAdmin" v-if="selected_question.question_audio_answer" controls ref="audio_answer" id="audio_answer" :volume=".2" :src="this.$store.state.API_URL + '/uploads/' + selected_question.question_audio_answer" ></audio>
                        </div>
                        <div v-else v-show="show_answer || $store.state.isAdmin && !$store.state.irl_mode" class="answer_container order_answer">
                            <div class="order_answer_item" v-for="(item, index) in [...selected_question.question_order_items].sort((a, b) => parseFloat(a.order) - parseFloat(b.order))">
                                {{ item.name }}<span>{{ item.value }}</span>
                            </div>
                        </div>
                    </Transition>
                </template>
            </Card>
        </Transition>
        <Buzzer v-show="!$store.state.isAdmin && (selected_question.question_type === 'blurred_image') && !show_answer" ref="buzzer" />
        <!--<div v-if="selected_question.question_type === 'simple_question'">
            <Transition>
                <SimpleQuestion v-show="this.$store.state.isAdmin && !this.$store.state.irl_mode || showQuestion" :selected_question="selected_question" />
            </Transition>
        </div>
        <div v-if="selected_question.question_type === 'blurred_image'">
            <Transition>
                <BlurredImageQuestion v-show="this.$store.state.isAdmin && !this.$store.state.irl_mode || showQuestion" :blur_lvl="blur_lvl" :selected_question="selected_question" /> 
            </Transition>
        </div>
        <div v-if="selected_question.question_type === 'audio'">
            <Transition>
                <AudioQuestion v-show="this.$store.state.isAdmin && !this.$store.state.irl_mode || showQuestion" :selected_question="selected_question" />
            </Transition>
        </div>-->

        <!--<Transition>
            <QuestionAnswer v-show="this.$store.state.isAdmin && !this.$store.state.irl_mode || show_answer" :question_answer="selected_question.question_answer" />
        </Transition>-->

        <!--<div class="user_input_container" v-show="!$store.state.isAdmin">
            <Buzzer v-if="selected_question.question_type === 'blurred_image' && !show_answer" />
            <TextAnswer ref="textAnswer" @logInAnswer="logInAnswer" v-if="selected_question.question_type === 'simple_question' || selected_question.question_type === 'audio'" />
        </div>-->
    </div>
</template>

<script>
    import { defineComponent } from 'vue';  
    import LobbyPlayerList from './LobbyPlayerList.vue';
    import QuestionAnswer from '../QuestionAnswer.vue';
    import SimpleQuestion from '../Questions/SimpleQuestion.vue';
    import OrderQuestion from '../Questions/OrderQuestion.vue';
    import BlurredImageQuestion from '../Questions/BlurredImageQuestion.vue';
    import AudioQuestion from '../Questions/AudioQuestion.vue';
    import Buzzer from '../PlayerInputs/Buzzer.vue';
    import TextAnswer from '../PlayerInputs/TextAnswer.vue';
    import OrderLockAnswer from '../PlayerInputs/OrderLockAnswer.vue';

    export default defineComponent({
        name: 'LobbyShowQuestion',
        components: {
            LobbyPlayerList,
            SimpleQuestion,
            OrderQuestion,
            BlurredImageQuestion,
            AudioQuestion,
            Buzzer,
            TextAnswer,
            QuestionAnswer,
            OrderLockAnswer
        },
        props: {
            selected_question: Object,
            show_answer: Boolean
        },
        data() {
            return {
                showQuestion: false,
                user_answer_value: '',
                blur_lvl: 5,
                reverse_audio_buffer: undefined,
                answer_locked: false
            }
        },
        methods: {
            logInAnswerOrderQuestion() {
                this.answer_locked = true;
                this.$socket.emit('answer-locked-order', this.$store.state.lobbyId, this.$store.state.username, JSON.stringify(this.selected_question.question_order_items));
            },  
            logInAnswer(answer) {
                this.$socket.emit('answer-locked', this.$store.state.lobbyId, this.$store.state.username, answer);
            },
            unblurImage() {
                this.$socket.emit("unblur-image", this.$store.state.lobbyId);
                this.blur_lvl = 0;
            },
            reduceBlur() {
                if (this.blur_lvl >= 1) {
                    this.$socket.emit("reduce-blur", this.$store.state.lobbyId);
                    this.blur_lvl -= 1;
                }
            },
            playAudio() {
                this.$socket.emit("play-audio", this.$store.state.lobbyId);
                this.$refs.audio.volume = .15;
                this.$refs.audio.play();
            },
            pauseAudio() {
                this.$socket.emit("pause-audio", this.$store.state.lobbyId);
                this.$refs.audio.pause();
            },
            closeQuestion() {
                if ("audio" in this.$refs) {
                    this.$refs.audio.pause();
                }

                this.showQuestion = false;
                this.showAnswer = false;
                this.blur_lvl = 5;
                this.answer_locked = false;
            },
        },
        /*mounted() {
            var context = new AudioContext(),
            request = new XMLHttpRequest();
            request.open('GET', this.audio_src, true);
            request.responseType = 'arraybuffer';
            request.addEventListener('load', function(){
                context.decodeAudioData(request.response, function(buffer){
                    var source = context.createBufferSource();
                    Array.prototype.reverse.call( buffer.getChannelData(0) );
                    Array.prototype.reverse.call( buffer.getChannelData(1) );
                    source.buffer = buffer;

                    this.reverse_audio_buffer = buffer;
                });
            });
            request.send();
        },*/
        sockets: {
            playerBuzzed: function (username) {
                this.$toast.open({
                    message: username + ' buzzed!',
                    type: 'success',
                });
            },
            lockPlayerInput: function () {
                this.answer_locked = true;
                this.$refs.textAnswer.lockPlayerInput();
            },
            showQuestion: function () {
                this.showQuestion = true;
            },
            /*closeQuestion: function () {
                if ("audio" in this.$refs) {
                    this.$refs.audio.pause();
                }

                this.showQuestion = false;
                this.showAnswer = false;
                this.blur_lvl = 5;

                this.$store.state.playerList.forEach(player => player.answer = '');

                this.$emit('closeQuestion');
            },*/
            unblureImage: function () {
                this.blur_lvl = 0;
            },
            reduceBlur: function () {
                //this.$refs.blurred_image.classList.remove('blur'+this.blur_lvl);
                this.blur_lvl -= 1;
                //this.$refs.blurred_image.classList.add('blur'+this.blur_lvl);
            },
            playAudio: function () {
                this.$refs.audio.volume = .15;
                this.$refs.audio.play();
            },
            pauseAudio: function () {
                this.$refs.audio.pause();
            }
        }
    })
</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.order_answer_item {
    padding: 10px 20px;
    background-color: rgb(214, 214, 214);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.order_answer_item span {
    font-size: 11px;
}

.order_answer {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.answer-enter-active {
  animation: answer-show .2s;
}

@keyframes answer-show {
  0% {
    height: 0;
  }
  100% {
    height: 100px;
  }
}


:deep(.p-inputtext) {
    border-color: rgb(231, 231, 231);
}

.lobby-show-question {
    display: flex;
    flex-direction: column;
    gap: 40px;
    min-height: 100vh;
    position: relative;
}

.question_card {
    position: absolute;
    left: 50%;
  transform: translateX(-50%);
    transition: all .2s ease;
}

.answer_card {
    background-color: transparent;
    color: white;
}

:deep(.p-card-body) {
    background-color: hsl(210, 25%, 94%);
    padding-left: 40px;
    padding-right: 40px;
}


:deep(.p-card) {
    width: 750px;
    overflow: hidden;
}

.answer_container {
    transition: all .2s ease;
    padding: 50px 10px 10px;
}

.answer_container > p {
    text-align: left;
    font-weight: 500;
}

.hideAnswer {
    height: 0;
}

.showAnswer {
    height: 100px;
}

@media (max-width: 815px) {
    .question_card {
        width: 90vw;
    }
}

@media (max-width: 580px) {
    :deep(.p-card-body) {
        padding-left: 20px;
        padding-right: 20px;
    }

}

</style>