<template>
  <div class="home">
	  <img src="../assets/quizme_logo3.png" />
    <div class="home_links">
      <router-link to="/create-lobby">
        Create Lobby
        <i class="fa-solid fa-gamepad-modern fa-xl"></i>
      </router-link>
      <router-link to="/join-lobby">
        Join Lobby
        <i class="fa-solid fa-right-to-bracket fa-xl"></i>
      </router-link>
      <router-link to="/list">
        View Quizes
        <i class="fa-solid fa-eye fa-xl"></i>
      </router-link>
      <router-link to="create-quiz">
        Create Quiz
        <i class="fa-solid fa-plus fa-xl"></i>
      </router-link>
    </div>
    <!--<div>
      <div id="slime_example"></div>
    </div>-->
  </div>
</template>

<script>
import router from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  /*mounted: () => {
    // IDLE SLIME ANIMATION
    var tID;
    let    position = 0; //start position for the image slicer
    const  interval = 700; //100 ms of interval for the setInterval()

    tID = setInterval ( () => {
      document.getElementById("slime_example").style.backgroundPosition = 
      `${position}px 160px`; 
      //we use the ES6 template literal to insert the variable "position"
      if (position < 80)
      { position = position + 80;}
      //we increment the position by 256 each time
      else
      { position = 0; }
      //reset the position to 256px, once position exceeds 1536px
    }
    , interval ); //end of setInterval

  }*/
})
</script>

<style scoped>

.home {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home img {
  width: 300px;
  height: 300px;
}

.home_links {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

a {
  width: 200px;
  padding: 20px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  text-decoration: none;
  background-color: hsl(210, 80%, 50%);
}

/*a {
  padding: 10px;
  background-color: hsl(217, 95%, 55%);
  color: hsl(210, 29%, 85%);
  border-right: none !important;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 22px;
  height: 180px;
  width: 250px;
}*/

a:hover {
  background-color: hsl(217, 95%, 45%);
}

#slime_example {
  height: 64px;
  width: 64px;
  background: url(../assets/slime/slime_idle1.png) 0px 160px;
  transform: scale(3);
}

@media (max-width: 600px) {
    .home {
      padding-top: 50px;
    }
}

</style>
