<template>
    <div class="player_overview_tile" :class="{hasAnswered: answer.length > 0}">
        <div @click="openPlayerActions">
            <!--<p>{{ place }}.</p>-->
            <p>{{ username }}</p>
            <Badge v-show="buzz_cnt > 0" class="player_buzzed_badge" :value="buzz_cnt"></Badge>
            <p class="player_points">{{ points }}</p>
            <!--<ButtonGroup class="user_actions_btns" v-if="$store.state.isAdmin">
                <Button @click="addPoints" severity="success">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                </Button>
                <Button @click="substractPoints" severity="warning">
                    <font-awesome-icon :icon="['fas', 'minus']" />
                </Button>
                <Button severity="danger">
                    <font-awesome-icon :icon="['fas', 'ban']" />
                </Button>
            </ButtonGroup>-->
        </div>
        <div v-show="(answer.length > 0 && $store.state.isAdmin) || show_answer" :class="{ hideAnswer: answer.length === 0, showAnswer: (answer.length > 0 && $store.state.isAdmin) || show_answer }">
            <p v-if="question_type !== 'order'" v-show="(answer.length > 0 && $store.state.isAdmin) || show_answer">{{ answer }}</p>
            <div v-else v-show="show_answer || $store.state.isAdmin" class="answer_container order_answer player_answer_order_items">
                <div class="player_answer_item" v-for="(item, index) in answer">
                    {{ item.name }}
                </div>
            </div>
            <ButtonGroup v-show="(answer.length > 0 && $store.state.isAdmin)">
                <Button @click="setAnswerCorrect" severity="success" :class="{not_selected: answer_correct !== 'correct'}">
                    <i class="fa-solid fa-check"></i>
                </Button>
                <Button @click="setAnswerPartly" severity="warning" :class="{not_selected: answer_correct !== 'partly'}">
                    <i class="fa-solid fa-minus"></i>
                </Button>
                <Button @click="setAnswerWrong" severity="danger" :class="{not_selected: answer_correct !== 'wrong'}">
                    <i class="fa-solid fa-xmark"></i>
                </Button>
            </ButtonGroup>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import { useConfirm } from "primevue/useconfirm";

    export default defineComponent({
        name: 'PlayerOverviewItem',
        props: {
            questionSelected: Boolean,
            username: String,
            answer: String,
            points: Number,
            place: Number,
            show_answer: Boolean,
            buzz_cnt: Number,
            answer_correct: String,
            question_type: String
        },
        data() {
            return {
                showAnswer: false,
                answerCorrect: false,
                answerPartly: false,
                answerWrong: false
            }
        },
        methods: {
            openPlayerActions() {
                this.$emit("openPlayerActions", this.username);
            },
            addPoints() {
                this.$socket.emit('add-points', this.$store.state.lobbyId, this.username);
            },
            substractPoints() {
                this.$socket.emit('subsctract-points', this.$store.state.lobbyId, this.username);
            },
            setAnswerCorrect() {
                this.answerWrong = false;
                this.answerPartly = false;
                this.answerCorrect = true;

                this.$socket.emit('set-answer-correct', this.$store.state.lobbyId, this.username);
            },
            setAnswerPartly() {
                this.answerWrong = false;
                this.answerPartly = true;
                this.answerCorrect = false;

                this.$socket.emit('set-answer-partly', this.$store.state.lobbyId, this.username);
            },
            setAnswerWrong() {
                this.answerWrong = true;
                this.answerPartly = false;
                this.answerCorrect = false;

                this.$socket.emit('set-answer-wrong', this.$store.state.lobbyId, this.username);
            }
        },
        sockets: {
            showAnswer: function () {
                this.showAnswer = true;
            }
        }
    })

</script>

<style scoped>

.player_answer_order_items {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.player_answer_item {
    padding: 10px 20px;
    background-color: rgba(229, 229, 229, 0.41);
    border-radius: 5px;
}

.not_selected {
    opacity: .4;
}

.loading_spinner {
    height: 30px;
    width: 30px;
    margin-top: 20px;
}

.player_overview_tile {
    width: 100%;
    background-color: rgb(237, 237, 237);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    transition: all .2s ease;
}

.player_overview_tile > div:nth-of-type(1):hover {
    cursor: pointer;
    background-color: rgb(211, 211, 211);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 10px;
}

.player_overview_tile > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.player_overview_tile > div:nth-of-type(1) {
    padding: 0 20px;
}

.player_overview_tile > div:nth-of-type(1) > p {
    font-size: 20px;
}

.player_overview_tile > div:nth-of-type(2) {
    padding: 10px 20px;
}

.player_overview_tile > div:nth-of-type(2) > p {
    margin: 0;
}

.player_points {
    min-width: 80px;
    text-align: right;
}

.hasAnswered {
    background-color: #3b82f6;
    color: white;
}

.hasAnswered > div:nth-of-type(1):hover {
    background-color: hsl(217, 91%, 50%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.showAnswer {
    height: auto;
    border-top: 1px solid white;
}

.hideAnswer {
    display: none;
    height: 0;
    padding: 0;
}

:deep(.p-button-group), :deep(.p-button) {
    height: 40px;
}

:deep(.p-button-group) {
    display: flex;
    flex-direction: row;
}

:deep(.p-button-outlined) {
    background-color: white;
}
</style>