<template>
    <div class="lobby-join">
        <h1>Join Lobby</h1>
        <div class="flex_col">
            <InputText v-model="username_value" placeholder="Username" type="text" maxlength="12" />
            <small v-show="username_already_taken" class="not_found_msg">Username already taken</small>
            <InputText v-model="lobby_id_value" placeholder="Lobby Code" type="text" maxlength="8" />
            <small v-show="lobby_not_found" class="not_found_msg">Lobby not found</small>
            <Button @click="joinLobby">
                <ProgressSpinner style="width: 20px; height: 20px; stroke: white;" v-show="loading" strokeWidth="5" class="loading_spinner" />
                <div v-show="!loading">
                    Join
                    <i class="fa-solid fa-right-to-bracket"></i>
                </div>
            </Button>
        </div>

        <Dialog :closable="false" :draggable="false" v-model:visible="show_dialog" modal header="Lobby already started" :style="{ width: '25rem' }">
            <p>Waiting for Lobby Leader to answer your join request...</p>
        </Dialog>

        <Dialog :draggable="false" v-model:visible="show_declined_dialog" modal header="Join request declined" :style="{ width: '25rem' }"></Dialog>
    </div>    
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LobbyJoin',
    data() {
        return {
            username_value: '',
            lobby_id_value: '',
            username_already_taken: false,
            lobby_not_found: false,
            loading: false,
            show_dialog: false,
            show_declined_dialog: false
        }
    },
    methods: {
        async joinLobby() {
            if (this.username_value.length > 0 && this.lobby_id_value.length > 0) {
                this.lobby_not_found = false;

                this.loading = true;

                // Check if lobby exists and if username is already taken
                this.$socket.emit('join-lobby', this.lobby_id_value, this.username_value)

                /*if (res.status === 404) {
                    this.lobby_not_found = true;
                } else {
                    this.$socket.emit('join-lobby', this.lobby_id_value, this.username_value);
                    this.$store.state.lobbyId = this.lobby_id_value;
                    this.$store.state.username = this.username_value;
                    this.$emit('openWaitingSection');
                }

                this.loading = false;*/
            }
        }
    },
    sockets: {
        joinRequestDeclined: function () {
            this.show_dialog = false;
            this.loading = false;
            this.show_declined_dialog = true;
        },
        joinRequestAccepted: function (data) {
            this.$store.state.playerList = data.playerlist;

            this.$store.state.lobbyId = this.lobby_id_value;
            this.$store.state.username = this.username_value;

            this.$store.state.quizId = data.quiz_id;
            this.$store.state.irl_mode = data.irl_mode;
            this.$router.push({ path: '/lobby' });
        },
        joinRoom: function (lobby_id) {
            this.$socket.emit('join-room', lobby_id);
        },
        waitForAdminAccept: function () {
            this.show_dialog = true;
        },
        joinLobbySuccesfull: function () {
            this.$store.state.lobbyId = this.lobby_id_value;
            this.$store.state.username = this.username_value;
            this.$emit('openWaitingSection');
        },
        usernameAlreadyTaken: function () {
            this.username_already_taken = true;
            this.loading = false;
        },
        lobbyNotFound: function () {
            this.lobby_not_found = true;
            this.loading = false;
        }
    }
});

</script>

<style scoped>

.lobby-join {
    padding-top: 200px;
    width: 500px;
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.flex_col button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.not_found_msg {
    color: red;
    text-align: left;
}

:deep(.p-progress-spinner-circle) {
    stroke: white !important;
}

@media (max-width: 600px) {
    .lobby-join {
        width: 90vw;
        padding-top: 50px;
    }
}

@media (max-width: 580px) {

    .lobby-join input {
        width: 100%;
        border-radius: 5px;
        height: 60px;
        font-size: 20px;
    }

}


</style>