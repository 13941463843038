<template>
  <nav id="page_nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/>
</template>

<style>

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

body {
  background-color: hsl(210, 29%, 9%);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: hsl(210, 29%, 80%);
}

#page_nav {
  display: none;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.tags_row {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
</style>
