<template>
    <div class="lobby">
        <LobbyJoin @openWaitingSection="openWaitingSection" v-if="!wait" />
        <LobbyWait v-else />
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import LobbyJoin from '../components/LobbyJoin.vue';
    import LobbyWait from '../components/LobbyWait.vue';

    export default defineComponent({
        name: 'LobbyJoinView',
        components: {
            LobbyJoin,
            LobbyWait
        },
        mounted() {
            this.$store.state.isAdmin = false
        },
        data() {
            return {
                wait: false
            }
        },
        methods: {
            openWaitingSection() {
                this.wait = true;
            }
        }
    })
</script>

<style scoped>
    .lobby {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
</style>