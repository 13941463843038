<template>
    <div class="lobby">
        <LobbyCreation />
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import LobbyCreation from '../components/LobbyCreation.vue';
    import LobbyPlayerList from '../components/Lobby/LobbyPlayerList.vue';
    import LobbyUser from '../class/LobbyUser.js';

    export default defineComponent({
        name: 'LobbyCreateView',
        components: {
            LobbyCreation,
            LobbyPlayerList
        },
        mounted() {
            this.$store.state.isAdmin = true;
        },
        sockets: {
            newPlayer: function (player) {
                this.$store.state.playerList.push(player);
            },
            removePlayer: function (username) {
                const index = this.$store.state.playerList.map(function(e) { return e.username; }).indexOf(username);

                if (index >= 0) {
                    this.$store.state.playerList.splice(index, 1);
                }
            },
        },
    })
</script>

<style scoped>

    .lobby {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
</style>