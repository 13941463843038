<template>
    <div class="blurred-image-question">
        <div class="tags_row">
            <Tag :value="selected_question.category" severity="danger" />
            <Tag :value="selected_question.question_points + ' Points'" severity="warning" />
        </div>
        <p class="question_text">{{ selected_question.question_text }}</p>
        <img ref="blurred_image" :class="'blurred_image blur'+blur_lvl" :src="this.$store.state.API_URL + '/uploads/' + selected_question.question_img" />
    </div>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'BlurredImage',
        props: {
            selected_question: Object,
            blur_lvl: Number
        },
        data() {
            return {
                user_answer_value: '',
                answer_locked: false,
            }
        },
        watch: {
            blur_lvl(newVal, oldVal) {
                this.$refs.blurred_image.classList.remove('blur'+this.oldVal);
                this.$refs.blurred_image.classList.add('blur'+this.newVal);
            }
        }
    })

</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.blurred-image-question {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

p {
    text-align: left;
}

.blurred_image {
    border-radius: 10px;
    transition: filter .2s ease;
    min-height: 500px;
    width: 500px;
    height: auto;
    max-height: 350px;
    object-fit: cover;
    margin: 20px auto;
}

.blur5 {
    filter: blur(25px);
}

.blur4 {
    filter: blur(20px);
}

.blur3 {
    filter: blur(15px);
}

.blur2 {
    filter: blur(10px);
}

.blur1 {
    filter: blur(5px);
}

</style>