<template>
    <div class="user_answer_row">
        <InputText v-on:keyup.enter="logInAnswer" :disabled="answer_locked" v-model="user_answer_value" placeholder="Your answer" type="text" />
        <Button :class="{ isLocked: answer_locked }" :disabled="answer_locked" @click="logInAnswer">
            <i v-show="!answer_locked" class="fa-solid fa-lock-open"></i>
            <i v-show="answer_locked" class="fa-solid fa-lock"></i>
        </Button>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'TextAnswer',
        data() {
            return {
                user_answer_value: '',
                answer_locked: false
            }
        },
        methods: {
            lockPlayerInput() {
                this.answer_locked = true;
            },
            logInAnswer() {
                this.answer_locked = true;
                this.$emit("logInAnswer", this.user_answer_value);
            }
        },
        sockets: {
            unlockAnswerInput: function () {
                this.answer_locked = false;
            },
            closeQuestion: function () {
                this.answer_locked = false;
                this.user_answer_value = '';
            }
        }
    })

</script>

<style scoped>

.user_answer_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.user_answer_row input {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.user_answer_row button {
    width: 70px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

:deep(.p-button.isLocked) {
    background-color: rgb(55, 55, 55) !important;
    border: 1px solid rgb(55, 55, 55) !important;
}

@media (max-width: 580px) {

    .user_answer_row {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .user_answer_row input {
        width: 100%;
        border-radius: 5px;
        height: 60px;
        font-size: 20px;
    }

    .user_answer_row button {
        width: 100%;
        border-radius: 5px;
    }

}

</style>