<template>
    <div>
        <Dialog :dismissableMask="true" :draggable="false" v-model:visible="show_modal" modal class="player-overview" :style="{ width: '800px' }">
            <template #header>
                <div class="header_row">
                    <p>Scoreboard</p>
                    <font-awesome-icon :icon="['fas', 'users']" />
                </div>
            </template>
            <div class="player_overview_container">
                <PlayerOverviewItem :question_type="question_type" @openPlayerActions="openPlayerActions" :show_answer="show_answer" :questionSelected="questionSelected" v-for="player in playerlist" :answer_correct="player.answer_correct" :buzz_cnt="player.buzz_cnt" :username="player.username" :answer="player.answer" :points="player.points" :place="player.place" />
            </div>
        </Dialog>
        <Dialog :dismissableMask="true" :draggable="false":style="{width: '600px'}" modal v-model:visible="show_user_actions_modal">
            <template #header>
                <p>Actions for <b>{{ actions_for }}</b></p>
            </template>
            <div class="user_action_container">
                <div class="user_action_row p-fluid">
                    <label>Add Points</label>
                    <ButtonGroup>
                        <Button class="points_control_btn" @click="addPoints(10)">+ 10</Button>
                        <Button @click="addPoints(50)">+ 50</Button>
                        <Button @click="addPoints(100)">+ 100</Button>
                        <Button @click="addPoints(200)">+ 200</Button>
                        <Button @click="addPoints(400)">+ 400</Button>
                    </ButtonGroup>
                    <div class="custom_points_container">
                        <InputNumber v-model="add_custom_points_value" placeholder="Custom points" />
                        <Button @click="addCustomPoints">
                            <i class="fa-solid fa-plus fa-xs"></i>
                        </Button>
                    </div>
                </div>
                <div class="user_action_row p-fluid">
                    <label>Remove Points</label>
                    <ButtonGroup>
                        <Button @click="removePoints(10)">- 10</Button>
                        <Button @click="removePoints(50)">- 50</Button>
                        <Button @click="removePoints(100)">- 100</Button>
                        <Button @click="removePoints(200)">- 200</Button>
                        <Button @click="removePoints(400)">- 400</Button>
                    </ButtonGroup>
                    <div class="custom_points_container">
                        <InputNumber v-model="remove_custom_points_value" placeholder="Custom points" />
                        <Button @click="removeCustomPoints">
                            <i class="fa-solid fa-minus fa-xs"></i>
                        </Button>
                    </div>
                </div>
                <div class="user_action_row">
                    <label>Other</label>
                    <Button @click="confirmKick" severity="danger">
                        Kick
                        <font-awesome-icon :icon="['fas', 'ban']" />
                    </Button>
                    <Button @click="unlockAnswerInput">
                        Reset answer
                        <font-awesome-icon :icon="['fas', 'rotate-left']" />
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import PlayerOverviewItem from './PlayerOverviewItem.vue';

    export default defineComponent({
        name: 'PlayerOverview',
        props: {
            questionSelected: Boolean,
            playerlist: Array,
            show_answer: Boolean,
            question_type: String
        },
        data() {
            return {
                show_modal: false,
                actions_for: '',
                add_custom_points_value: undefined,
                remove_custom_points_value: undefined,
                show_user_actions_modal: false,
            }
        },
        components: {
            PlayerOverviewItem
        },
        methods: {
            unlockAnswerInput() {
                this.$socket.emit("unlock-answer-input", this.$store.state.lobbyId, this.actions_for);
            },
            confirmKick() {
                const kick = confirm("Kick player "+this.actions_for+"?");

                if (kick) {
                    this.$socket.emit("kick-player", this.$store.state.lobbyId, this.actions_for);
                    this.show_user_actions_modal = false;
                }
            },
            addCustomPoints() {
                if (this.add_custom_points_value) {
                    this.$socket.emit('add-points', this.$store.state.lobbyId, this.actions_for, this.add_custom_points_value);
                    this.add_custom_points_value = undefined;
                }
            },
            removeCustomPoints() {
                if (this.remove_custom_points_value) {
                    this.$socket.emit('remove-points', this.$store.state.lobbyId, this.actions_for, this.remove_custom_points_value);
                    this.remove_custom_points_value = undefined;
                }
            },
            addPoints(points) {
                this.$socket.emit('add-points', this.$store.state.lobbyId, this.actions_for, points);
            },
            removePoints(points) {
                this.$socket.emit('remove-points', this.$store.state.lobbyId, this.actions_for, points);
            },
            openPlayerActions(username) {
                this.actions_for = username;
                this.show_user_actions_modal = true;
            },
            showPlayerOverview() {
                this.show_modal = true;
            }
        }
    })

</script>

<style scoped>

.custom_points_container {
    display: flex;
    flex-direction: row;
}

.custom_points_container > button {
    height: 38px;
    width: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.p-inputnumber-input) {
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 200px;
}

.btn_group button svg {
    margin-left: 10px;
}

.user_action_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user_action_row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user_action_row > button {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.player_overview_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
}

.close_modal_btn {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header_row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center
}

.header_row p {
    margin: 0;
}

:deep(.p-button) {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>