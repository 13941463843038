<template>
    <Dialog modal :draggable="false" :closable="false" v-model:visible="visible" class="disconnect-dialog">
        <template #header>
            <p class="dialog_title">Disconnected</p>
        </template>
        <p>You lost connection to the server!</p>
        <Button @click="reconnect()">
            <ProgressSpinner style="width: 20px; height: 20px; stroke: white;" v-show="loading" strokeWidth="5" class="loading_spinner" />
            <div v-show="!loading">
                Reconnect
                <font-awesome-icon :icon="['fas', 'plug']" />
            </div>
        </Button>
    </Dialog>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'DisconnectDialog',
        props: {
            visible: Boolean
        },
        data() {
            return {
                loading: false
            }
        },
        methods: {
            reconnect() {
                this.loading = true;
                this.$socket.emit("reconnect", this.$store.state.lobbyId);
            }
        }
    })

</script>

<style scoped>

.dialog_title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 20px;
}

button > div {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

:deep(.p-progress-spinner-circle) {
    stroke: white !important;
}

</style>