<template>
    <div class="admin-menu-bar">
        <Button @click="showPlayerOverview" severity="success" outlined>
            <font-awesome-icon :icon="['fas', 'users']" />
        </Button>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'AdminMenuBar',
        props: {
            question_selected: Boolean,
            selected_question_type: String
        },
        data() {
            return {
                show_question: false,
                show_answer: false
            }
        },
        methods: {
            showPlayerOverview() {
                this.$emit("showPlayerOverview");
            },
            resetBuzzers() {
                this.$socket.emit("resetBuzzers", this.$store.state.lobbyId);

                this.$emit("resetBuzzers");
            },
            startTimer(seconds) {
                this.$socket.emit("start-timer", this.$store.state.lobbyId, seconds);
            },
            showQuestion() {
                this.show_question = true;
                this.$socket.emit('show-question', this.$store.state.lobbyId);
            },
            playAudio() {
                this.$emit("playAudio");
            },
            pauseAudio() {
                this.$emit("pauseAudio");
            },
            reduceBlur() {
                this.$emit("reduceBlur");
            },
            showAnswer() {
                this.show_answer = true;
                this.$socket.emit('show-answer', this.$store.state.lobbyId);
            },
            closeQuestion() {
                // this.$socket.emit('close-question', this.$store.state.lobbyId);

                this.show_question = false;
                this.show_answer = false;

                this.$store.state.playerList.forEach(player => player.answer = '');
                
                this.$emit('closeQuestion');

                this.resetBuzzers();
            },
            showLobbyId() {
                alert(this.$store.state.lobbyId);
            }
        }
    })

</script>

<style scoped>

.admin-menu-bar {
    background-color: hsl(210, 29%, 9%);
    padding: 10px 20px;
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    left: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18);
}

.admin-menu-bar button {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

</style>