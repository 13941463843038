<template>
    <Card class="player-list-item">
        <template v-if="$store.state.isAdmin && answer.length > 0 || showAllAnswers" #header>
            <div v-show="answer.length > 0" class="player_item_header">
                <p>Answered</p>
                <p>{{ answer }}</p>
            </div>
        </template>
        <!-- <template #title>
            <Avatar :label="username[0]" style="background-color: #ece9fc; color: #2a1261" shape="circle" />
        </template> -->
        <template #subtitle>
            <Badge v-show="buzz_cnt > 0" class="player_buzzed_badge" :value="buzz_cnt"></Badge>
            <p>{{ username }}</p>
        </template>
        <template #content>
            <p :class="{ add_padding: !this.$store.state.isAdmin }">{{ points }}</p>
        </template>
        <template #footer v-if="$store.state.isAdmin">
            <ButtonGroup class="player_points_btn_group">
                <Button @click="addPoints" severity="success">
                    <i class="fa-solid fa-plus fa-xs"></i>
                </Button>
                <Button @click="substractPoints" severity="secondary">
                    <i class="fa-solid fa-minus fa-xs"></i>
                </Button>
            </ButtonGroup>
        </template>
    </Card>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PlayerListItem',
    props: {
        username: String,
        answer: String,
        points: Number,
        buzz_cnt: Number
    },
    data() {
        return {
            showAllAnswers: false,
        }
    },
    methods: {
        addPoints() {
            this.$socket.emit('add-points', this.$store.state.lobbyId, this.username);
        },
        substractPoints() {
            this.$socket.emit('subsctract-points', this.$store.state.lobbyId, this.username);
        }
    },
    sockets: {
        showAnswer: function () {
            this.showAllAnswers = true;
        },
        closeQuestion: function () {
            this.showAllAnswers = false;
        }
    }
})

</script>

<style scoped>

.player_buzzed_badge {
    position: absolute;
    top: 10px;
    right: 10px;
}

.answered_tag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.player-list-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    position: relative;
    width: 200px;
}

.player-list-item p {
    margin: 0;
}

.player_item_header {
    background-color: aliceblue;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.player_item_header p:nth-of-type(1) {
    font-weight: bold;
}

.player_item_header p {
    font-size: 12px;
    overflow-wrap: break-word;
}

.add_padding {
    padding-bottom: 20px;
}

:deep(.p-card-body) {
    padding: 24px 0 0 0 !important;
}

:deep(.p-button-group) {
    display: flex;
    flex-direction: row;
}

:deep(.p-button-group > button) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    display: flex;
    justify-content: center;
}

:deep(.p-button-group), :deep(.p-button-group > button) {
    width: 100% !important;
}

</style>