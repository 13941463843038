<template>
    <div class="create-quiz-categories">
        <div>
            <h1>Categories</h1>
            <div class="flex_row">
                <div class="flex_col">
                    <label>Category 1</label>
                    <InputText v-model="category_1" type="text" />
                </div>
                <div class="flex_col">
                    <label>Category 2</label>
                    <InputText v-model="category_2" type="text" />
                </div>
                <div class="flex_col">
                    <label>Category 3</label>
                    <InputText v-model="category_3" type="text" />
                </div>
                <div class="flex_col">
                    <label>Category 4</label>
                    <InputText v-model="category_4" type="text" />
                </div>
                <div class="flex_col">
                    <label>Category 5</label>
                    <InputText v-model="category_5" type="text" />
                </div>
            </div>
            <Button :disabled="category_1.length === 0 || category_2.length === 0 || category_3.length === 0 || category_4.length === 0 || category_5.length === 0" @click="setQuizCategories">
                Continue
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </Button>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CreateQuizName',
    data() {
        return {
            category_1: '',
            category_2: '',
            category_3: '',
            category_4: '',
            category_5: ''
        }
    },
    methods: {
        setQuizCategories() {
            this.$emit('setQuizCategories', [this.category_1, this.category_2, this.category_3, this.category_4, this.category_5])
        }
    }
})

</script>


<style scoped>

.create-quiz-categories {
    display: flex;
    justify-content: center;
}

.create-quiz-categories > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.flex_row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

button {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

</style>