<template>
    <div class="create-quiz-view">
        <!--<Steps v-show="active < 3" v-model:activeStep="active" :model="steps" />-->
        <!-- <CreateQuizName @setQuizName="setQuizName" v-show="active === 0" />
        <CreateQuizCategories @setQuizCategories="setQuizCategories" v-show="active === 1" /> -->
        <router-link to="/"><i class="fa-solid fa-arrow-left fa-sm"></i> Back</router-link>
        <CreateQuizQuestions @createQuiz="createQuiz" @addQuestion="addQuestion" v-show="active === 2" />
        <CreateQuizFinished :done="done" :progress="progress" v-show="active === 3" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import CreateQuizName from '../components/CreateQuiz/CreateQuizName.vue';
import CreateQuizCategories from '../components/CreateQuiz/CreateQuizCategories.vue';
import CreateQuizQuestions from '../components/CreateQuiz/CreateQuizQuestions.vue';
import CreateQuizFinished from '../components/CreateQuiz/CreateQuizFinished.vue';

export default defineComponent({
    name: 'CreateQuizView',
    components: {
        CreateQuizName,
        CreateQuizCategories,
        CreateQuizQuestions,
        CreateQuizFinished
    },
    data() {
        return {
            active: 2,
            steps: [
                { label: 'Name' },
                { label: 'Categories' },
                { label: 'Questions' }
            ],
            quiz: {
                name: '',
                categories: [],
            },
            progress: 0,
            done: false
        }
    },
    methods: {
        async createQuiz(questions, quizname, categories) {

            this.quiz.name = quizname;
            this.quiz.categories = categories;

            this.active = 3;

            // Add Quiz & Categories
            let res = await fetch(this.$store.state.API_URL+'/create-quiz-meta', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(this.quiz)
            });

            this.progress += (1/21) * 100;

            const quiz_id_data = await res.json();

            // Upload question one by one (todo: make that more efficient by reducing number of requests)
            questions.forEach(async question => {
                const formData = new FormData();
                Object.keys(question).forEach(key => {
                    formData.append(key, question[key])
                });
                formData.append("quiz_id", quiz_id_data.quiz_id);

                let res = await fetch(this.$store.state.API_URL+'/create-quiz', {
                    method: 'POST',
                    body: formData
                });
                this.progress += (1/21) * 100;
            });

            this.done = true;
        },
        setQuizName(name) {
            this.quiz.name = name;
            this.active = 1;
        },
        setQuizCategories(categories) {
            this.quiz.categories = categories;
            this.active = 2;
        },
        addQuestion(data) {
            this.questions.push(data);
        }
    }
});
</script>

<style scoped>

    a {
        color: black;
        text-decoration: none;
        position: absolute;
        left: 50px;
        top: 30px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        background-color: white;
        border-radius: 5px;
        padding: 5px 15px;
    }

    .create-quiz-view {
        padding: 20px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .create-quiz-view nav {
        width: 100%;
    }

    #create_quiz_form {
        width: 100%;
        text-align: left;
        padding: 50px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .form_row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
    }

    .form_col {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .category_col {
        width: 100%;
    }

    :deep(.p-menuitem-link) {
        background-color: transparent;
    }

</style>