<template>
    <div class="create-quiz-finished">
        <ProgressBar v-show="!done" :value="this.progress" style="height: 20px; width: 500px;"></ProgressBar>
        <div v-show="done">
            <h1>
                Quiz created
                <font-awesome-icon :icon="['fas', 'rocket']" />
            </h1>
            <Button @click="this.$router.push({path: '/'})">
                Return to menu
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </Button>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CreateQuizFinished',
    props: {
        progress: Number,
        done: Boolean
    },
})

</script>

<style scoped>

.create-quiz-finished {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create-quiz-finished button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

</style>