<template>
    <div class="lobby">
        <!-- Toast to show when player disonnected -->
        <Toast />
        <!-- Dialog to inform user when disconnected from backend server -->
        <DisconnectDialog :visible="disconnected" />
        <!-- Timer display -->
        <Timer />

        <!-- Loading while quiz data is being loaded -->
        <div v-show="loading">
            <ProgressSpinner strokeWidth="5" class="loading_spinner" />
            <p>Loading quiz data</p>
        </div>

        <!-- Show question select table -->
        <LobbySelectQuestion :quiz="quiz" @openQuestion="openQuestion" v-show="showSelectQuestion && !loading" />
        
        <!-- Show selected question -->
        <LobbyShowQuestion :show_answer="show_answer" ref="lobbyShowQuestion" @closeQuestion="closeQuestion" :selected_question="selected_question" v-show="showQuestion && !loading" />

        <!-- Function Buttons for Lobby Admin -->
        <AdminMenuBar ref="adminMenuBar" @showPlayerOverview="showPlayerOverview" @resetBuzzers="resetBuzzers" :selected_question_type="selected_question.question_type" @pauseAudio="pauseAudio" @playAudio="playAudio" @reduceBlur="reduceBlur" :question_selected="showQuestion" @showSummary="showSummary" @closeQuestion="closeQuestion" @unblurImage="unblurImage" v-if="$store.state.isAdmin" />

        <!-- Player overview for Lobby Admin -->
        <PlayerOverview :question_type="selected_question.question_type" :show_answer="show_answer" ref="playerOverview" :playerlist="this.$store.state.playerList" :questionSelected="showQuestion" @closePlayerOverview="closePlayerOverview" />

        <!-- Player Join Request Dialog -->
        <Dialog :closable="false" :draggable="false" v-model:visible="show_dialog_join_request" modal header="Player Join Request" :style="{ width: '25rem' }">
            <p>Player <span class="hightlight_text">{{ player_join_request.username }}</span> wants to join the lobby.</p>
            <div class="button_row">
                <ButtonGroup>
                    <Button type="button" label="Accept" @click="acceptPlayer" ></Button>
                    <Button type="button" label="Decline" severity="secondary" @click="declinePlayer" ></Button>
                </ButtonGroup>
            </div>
        </Dialog>

        <!-- You got kicked dialog -->
        <Dialog class="kicked_modal" :closable="false" :draggable="false" v-model:visible="show_dialog_kicked" modal header="You got kicked" :style="{ width: '25rem' }">
            <a class="p-button p-component" href="/">
                Return to homepage
                <font-awesome-icon :icon="['fas', 'right-from-bracket']" />
            </a>
        </Dialog>

        <!-- Question close summary Dialog -->
        <Dialog class="summary_dialog" header="Summary" modal :closable="false" :draggable="false" v-model:visible="show_dialog_summary" :style="{ width: '45rem' }">
            <div class="summary_container">
                <div :class="{correct: player.answer_correct === 'correct', partly: player.answer_correct === 'partly', wrong: player.answer_correct === 'wrong'}" class="summary_player_tile" v-for="player in this.$store.state.playerList">
                    <div>
                        <p>{{ player.username }}</p>
                        <p>{{ player.points }}</p>
                    </div>
                    <div v-show="selected_question.question_type !== 'order' && player.answer.length > 0">
                        <p>{{ player.answer }}</p>
                    </div>
                    <div class="player_answer_order_items" v-show="selected_question.question_type === 'order' && player.answer.length > 0">
                        <div class="player_answer_item" v-for="(item, index) in player.answer">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
            <Button v-if="$store.state.isAdmin" @click="closeQuestion">Continue</Button>
        </Dialog>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import LobbySelectQuestion from '../components/Lobby/LobbySelectQuestion.vue';
    import LobbyShowQuestion from '../components/Lobby/LobbyShowQuestion.vue';
    import LobbyPlayerList from '../components/Lobby/LobbyPlayerList.vue'; 
    import AdminMenuBar from '../components/AdminComponents/AdminMenuBar.vue';
    import PlayerMenuBar from '../components/PlayerMenuBar.vue';
    import Timer from '../components/Timer.vue';
    import DisconnectDialog from '../components/Dialogs/DisconnectDialog.vue';
    import PlayerOverview from '../components/Lobby/PlayerOverview.vue';


    export default defineComponent({
        name: 'LobbyView',
        components: {
            LobbySelectQuestion,
            LobbyShowQuestion,
            LobbyPlayerList,
            AdminMenuBar,
            Timer,
            DisconnectDialog,
            PlayerOverview,
            PlayerMenuBar
        },
        data() {
            return {
                // Quiz data
                quiz: {
                    name: "Quiz name",
                    categories: [
                        "Category 1",
                        "Category 2",
                        "Category 3",
                        "Category 4",
                        "Category 5"
                    ],
                    questions: [
                        { question_text: "This is the question 100 for category 1", question_answer: "This is the answer", category: "Category 1", question_points: 100, question_type: 'simple_question' },
                        { question_text: "This is the question 200 for category 1", question_answer: "This is the answer", category: "Category 1", question_points: 200, question_type: 'simple_question' },
                        { question_text: "This is the question 300 for category 1", question_answer: "This is the answer", category: "Category 1", question_points: 300, question_type: 'simple_question' },
                        { question_text: "This is the question 400 for category 1", question_answer: "This is the answer", category: "Category 1", question_points: 400, question_type: 'simple_question' },
                        { question_text: "This is the question 100 for category 2", question_answer: "This is the answer", category: "Category 2", question_points: 100, question_type: 'simple_question' },
                        { question_text: "This is the question 200 for category 2", question_answer: "This is the answer", category: "Category 2", question_points: 200, question_type: 'simple_question' },
                        { question_text: "This is the question 300 for category 2", question_answer: "This is the answer", category: "Category 2", question_points: 300, question_type: 'simple_question' },
                        { question_text: "This is the question 400 for category 2", question_answer: "This is the answer", category: "Category 2", question_points: 400, question_type: 'simple_question' },
                    ]
                },
                // Show different lobby sections
                loading: true,
                showSelectQuestion: true,
                showQuestion: false,
                // Selected question data
                selected_question: {},
                // Other data
                disconnected: false,
                buzz_cnt: 1,
                player_join_request: {},
                show_answer: false,
                // Dialogs
                show_dialog_join_request: false,
                show_dialog_kicked: false,
                show_dialog_summary: false
            }
        },
        async mounted() {
            // Load Quiz data
            let res = await fetch(this.$store.state.API_URL + "/get-quiz/" + this.$store.state.quizId, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            })

            this.quiz = await res.json();
            console.log(this.quiz)
            this.loading = false;
        },
        methods: {
            unblurImage() {
                this.$refs.lobbyShowQuestion.unblurImage();
            },
            closePlayerOverview() {
                this.showPlayerOverviewModal = false;
                this.$socket.emit("close-player-overview", this.$store.state.lobbyId);
            },
            showPlayerOverview() {
                this.$refs.playerOverview.showPlayerOverview();
            },
            declinePlayer() {
                this.$socket.emit('descline-join-request', this.player_join_request.socket);
                this.show_dialog_join_request = false;
            },
            acceptPlayer() {
                this.$socket.emit('accept-join-request', this.$store.state.lobbyId, this.$store.state.quizId, this.player_join_request.socket, this.player_join_request.username, this.$store.state.irl_mode);
                this.show_dialog_join_request = false;
            },
            resetBuzzers() {
                this.$socket.emit("resetBuzzers", this.$store.state.lobbyId);

                this.$store.state.playerList.map(player => {
                    player.buzz_cnt = 0;
                });

                this.buzz_cnt = 1;
            },
            showSummary() {
                if (this.selected_question.question_type !== 'blurred_image') {
                    // Check first if all player answers got checked by admin
                    let all_checked = true;
                    this.$store.state.playerList.forEach(player => {
                        if (player.answer_correct.length === 0) {
                            all_checked = false;
                        }
                    });

                    console.log(all_checked);
                    console.log(this.$store.state.playerList)

                    if (!all_checked) {
                        alert("Check all player answers before closing the question!");
                    } else {
                        this.show_dialog_summary = true;
                        this.$socket.emit('show-summary', this.$store.state.lobbyId);
                    }
                } else {
                    this.closeQuestion();
                }
            },
            closeQuestion() {
                this.show_dialog_summary = false;

                if (this.$store.state.isAdmin) {
                    this.$socket.emit('close-question', this.$store.state.lobbyId, this.selected_question);
                }

                this.$refs.lobbyShowQuestion.closeQuestion();
                this.$refs.adminMenuBar.resetValues();

                this.showQuestion = false;
                this.showSelectQuestion = true;
                this.show_answer = false;
            },
            openQuestion(row, col) {
                this.selected_question = this.quiz.questions.filter(question => question.category === this.quiz.categories[col-1] && question.question_points === row)[0];
                
                this.showQuestion = true;
                this.showSelectQuestion = false;
            },
            reduceBlur() {
                this.$refs.lobbyShowQuestion.reduceBlur();
            },
            playAudio() {
                this.$refs.lobbyShowQuestion.playAudio();
            },
            pauseAudio() {
                this.$refs.lobbyShowQuestion.pauseAudio();
            }
        },
        sockets: {
            closeQuestion: function () {
                this.show_dialog_summary = false;

                this.$refs.lobbyShowQuestion.closeQuestion();

                this.showQuestion = false;
                this.showSelectQuestion = true;
                this.show_answer = false;
            },
            showSummary: function () {
                this.show_dialog_summary = true;
            },
            gotKicked: function () {
                this.show_dialog_kicked = true;
            },
            playerDisconnected: function (data) {
                this.$toast.open({
                    message: data.username + ' left the lobby',
                    type: 'error',
                });
            },
            showAnswer: function () {
                this.show_answer = true;
            },
            closePlayerOverview: function () {
                this.showPlayerOverviewModal = false;
            },
            showPlayerOverview: function () {
                this.$refs.playerOverview.showPlayerOverview();
            },
            updatePlayerList: function(player_list) {
                this.$store.commit('updatePlayerlist', player_list);
            },
            playerJoinRequest: function (data) {
                this.player_join_request = data;
                this.show_dialog_join_request = true;
            },
            disconnect: function () {
                this.disconnected = true;
            },
            buzz: function (username) {
                this.$store.state.playerList.map(player => {
                    if (player.username === username) {
                        player.buzz_cnt = this.buzz_cnt
                    };
                });

                this.buzz_cnt++;
            }
        }
    })
</script>

<style scoped>

    .player_answer_order_items {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .player_answer_item {
        padding: 10px 20px;
        background-color: rgba(229, 229, 229, 0.41);
        border-radius: 5px;
    }

    .summary_dialog button {
        margin-top: 10px;
    }

    .summary_container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .summary_player_tile {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        color: white;
    }

    .summary_player_tile > div {
        padding: 15px 20px;
    }

    .summary_player_tile > div:nth-of-type(1) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .summary_player_tile > div:nth-of-type(1) > p {
        font-size: 20px;
    }

    .summary_player_tile p {
        margin: 0;
    }

    .kicked_modal a svg {
        margin-left: 10px;
    }

    a {
        text-decoration: none
    }

    .loading_spinner {
        margin-top: 200px;
    }

    @keyframes puff-out-center {
    0% {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
    }
    15% {
        filter: blur(0px);
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        filter: blur(4px);
        opacity: 0;
    }
    }


    .puff-out-center {
        animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    }
    .lobby {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .quiz_table tr {
        padding: 10px;
    }

    .quiz_table th, .quiz_table td {
        padding: 10px;
    }

    .quiz_table td button {
        width: 200px;
        height: 100px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .button_row {
        width: 100%;
    }

    .hightlight_text {
        font-weight: bold;
    }

    .correct {
        background-color: #80BE41;
    }

    .partly {
        background-color: #FD8C4D;
    }

    .wrong {
        background-color: #E24848;
    }
</style>