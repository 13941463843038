import { createStore } from 'vuex'

export default createStore({
  state: {
    //API_URL: 'http://felixthiele.me:8000',
    //API_URL: 'http://localhost:8000',
    API_URL: process.env.VUE_APP_BACKEND_URL,
    // Lobby Settings
    playerList: [],
    quizId: '',
    lobbyId: '',
    irl_mode: false,
    // User Settings
    isAdmin: false,
    username: ''
  },
  getters: {
  },
  mutations: {
    updatePlayerlist(state, newPlayerlist) {
      state.playerList = [...newPlayerlist];
    },
  },
  actions: {
  },
  modules: {
  }
})
