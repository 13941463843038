<template>
    <div class="simple-question">
        <div class="tags_row">
            <Tag :value="selected_question.category" severity="danger" />
            <Tag :value="selected_question.question_points + ' Points'" severity="warning" />
        </div>
        <p class="question_text">{{ selected_question.question_text }}</p>
        <img v-if="selected_question.question_img" class="question_img_header" alt="question image header" :src="this.$store.state.API_URL + '/uploads/' + selected_question.question_img" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Simple',
        props: {
            selected_question: Object,
        },
        data() {
            return {

            }
        },
    })

</script>

<style scoped>
.simple-question {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

p {
    text-align: left;
}

.question_img_header {
    height: 300px;
    max-height: 300px;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.question_text {
    font-weight: 500;
    font-size: 18px;
    margin: 30px 0;
}

</style>